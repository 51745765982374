import { MenuViewPanel, Roles } from "./types/enum";

export const posPaths = [
  // { title: "Activity Log", path: '/activitylog', icon: "home", roles: [Roles.merchant, Roles.outlet] as String[], panel: MenuViewPanel.LEFTNAV },
  {
    title: "Cart",
    path: "/cart",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Category",
    path: "/category",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Products",
    path: "/products",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Service",
    path: "/service",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Multi Service",
    path: "/multiservice",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Plan Management",
    path: "/plan",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  // {
  //   title: "Course",
  //   path: "/course",
  //   icon: "home",
  //   roles: [Roles.merchant, Roles.outlet] as String[],
  //   panel: MenuViewPanel.LEFTNAV,
  // },
  // { title: "Group", path: '/group', icon: "home", roles: [Roles.merchant, Roles.outlet] as String[], panel: MenuViewPanel.LEFTNAV },
];
