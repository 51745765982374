import {
  Box,
  Card,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useEffect } from "react";
import { get } from "../../services/api.service";
import CustomDataTable from "src/components/CustomDataTable";

const InventoryReport: React.FC = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [assignstock, setAssignstock] = React.useState<any[]>([]);
  const columns = [
    { label: "#", field: "index" },
    { label: "Date", field: "created_at" },
    { label: "Outlet Name", field: "outlet_username" },
    { label: "Product Name", field: "productName" },
    { label: "Stock Quantity", field: "assignstock" },
    { label: "Remark", field: "remarks" },
    { label: "Status", field: "status" },
  ];

  async function getAllStock() {
    try {
      const url = "/assignstock/report/getall";
      const response = await get(url);
      if (response?.data?.success) {
        setAssignstock(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllStock();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        assignstock?.filter((item: any) => {
          return (
            (item?.productName &&
              item?.productName
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.status &&
              item?.status.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(assignstock);
    }
  }, [searchQuery, assignstock]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Inventory Report</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Messages"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => {}}
      />
    </Stack>
  );
};

export default InventoryReport;
