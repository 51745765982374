import { Card, Grid, Typography } from "@mui/material";
import AppointmentDetailCard from "./AppointmentDetailCard"; // Import AppointmentDetailCard

const TimeSlotCard = ({ timeRange, appointments, viewAppointment }: any) => {
  return (
    <Card
      style={{ margin: "20px 0", padding: "15px", backgroundColor: "#f5f5f5" }}
    >
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        {timeRange} {/* Example: "11:00am - 11:30am" */}
      </Typography>

      <Grid container spacing={2}>
        {appointments.map((appointment: any, index: any) => (
          <Grid item xs={12} key={index}>
            <AppointmentDetailCard
              appointment={appointment}
              viewAppointment={viewAppointment}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default TimeSlotCard;
