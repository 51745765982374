import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  DialogActions,
  Grid,
} from "@mui/material";
import {
  addItem,
  clearAllItems,
  deleteItemById,
  getItems,
  updateItemById,
} from "../../libs/indexedDb";
import {
  Add,
  Clear,
  Delete,
  Close,
  Remove,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import html2pdf from "html2pdf.js";
import { get, post } from "../../services/api.service";
import { useAlert } from "src/components/CommonAlert";

const CartPage: React.FC = () => {
  const scrollContainerRef: any = useRef(null);
  const [mainCategory, setMainCategory] = useState([]);
  const [categoryNamesByMainCategory, setCategoryNamesByMainCategory] =
    useState([]);
  const [productByCategory, setProductByCategory] = useState([]);
  const [products, setProducts] = useState<any>([]);
  const [cartItems, setCartItems] = useState([]);
  // const [voucher] = useState<string>("");
  const [voucherValue] = useState<number>(0.0);
  const [voucherType] = useState<string>("");
  const [voucherApply] = useState<boolean>(false);

  const [total, setTotal] = useState<number>(0.0);
  const [discount, setDiscount] = useState<number>(0.0);
  const [cashTopup, setCashTopup] = useState<number>(0.0);
  const [BankTransfer, setBankTransfer] = useState<number>(0.0);
  const [CreditCard, setCreditCard] = useState<number>(0.0);
  const [TouchGo, setTouchGo] = useState<number>(0.0);
  const [netAmount, setNetAmount] = useState<number>(0.0);
  const [partialAmount, setPartialAmount] = useState<number>(0.0);
  const [value, setValue] = useState<string>("0.0");
  const [paymentAmt, setPaymentAmt] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<any>([]);
  const [serviceStaffIds, setServiceStaffIds] = useState<any>([]);

  const [memberDetails, setMemberDetails] = useState<any>();

  const [currentView, setCurrentView] = useState<string>("service");
  const [currentCategoryView, setCurrentCategoryView] = useState<string>("all");

  const [checkout, setCheckout] = useState(false);
  const [visibleCheckout, setVisibleCheckout] = useState(false);
  const [visibleStaff, setVisibleStaff] = useState<boolean>(false);
  const [visibleServiceStaff, setVisibleServiceStaff] =
    useState<boolean>(false);
  const [visibleSalesStaff, setVisibleSalesStaff] = useState<boolean>(false);
  const [visibleKivQty, setVisibleKivQty] = useState<boolean>(false);
  const [selectedKivItem, setSelectedKivItem] = useState<any>(null);
  const [kivquantity, setKivQuantity] = useState("");
  const [redeemedItems, setRedeemedItems] = useState<any[]>([]);

  const [staffData, setStaffData] = useState<StaffItem[]>([]);
  const [itemId, setItemId] = useState("");
  const [discountDialogVisible, setDiscountDialogVisible] =
    useState<boolean>(false);

  const [discountDialogOpenItem, setDiscountDialogOpenItem] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  // const [checked, setChecked] = useState<boolean>(false);

  const [printReceipt, setPrintReceipt] = useState<boolean>(false);
  const [visibleUnitPrice, setvisibleUnitPrice] = useState<boolean>(false);

  const [visibleAssistStaff, setvisibleAssistStaff] = useState<boolean>(false);

  const [cashPaidValue, setCashPaidValue] = useState("0");

  const [staffGroups, setStaffGroups] = useState<any>([]);
  const [ServiceStaffGroups, setServiceStaffGroups] = useState<any>([]);

  const [salesStaffIds, setSalesStaffIds] = useState<any>([]);
  const [SalesStaffGroups, setSalesStaffGroups] = useState<any>([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const [memberData, setMemberData] = useState([]);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [memberError, setMemberError] = useState("");
  const [error, setError] = useState("");
  const [kiverror, setKivError] = useState("");

  const [courseModalVisible, setCourseModalVisible] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [isCourse, setIsCourse] = useState(false);

  const [selectedCourseItem, setSelectedCourseItem] = useState<any>(null);
  const [courseQuantity, setCourseQuantity] = useState("");
  const [courseError, setCourseError] = useState("");
  const [visibleCourseQty, setVisibleCourseQty] = useState(false);
  const [redeemedCourses, setRedeemedCourses] = useState<any>([]);

  // Define the state to hold repayment details
  const [repaymentDialogOpen, setRepaymentDialogOpen] = useState(false);
  const [selectedRemainingPayments, setSelectedRemainingPayments] =
    useState<any>(null);
  const [amount, setAmount] = useState<number | string>("");
  const [repaymentsArray, setRepaymentsArray] = useState<any[]>([]);
  const [repaymenterror, setrepaymentsError] = useState<string>("");
  const [totalRepaymentAmount, setTotalRepaymentAmount] = useState<number>(0.0);

  const [isKIV, setIsKIV] = useState(false);

  const { showAlert } = useAlert();

  const handleRepaymentClick = (rowData: any) => {
    setSelectedRemainingPayments(rowData);
    setRepaymentDialogOpen(true);
    setrepaymentsError("");
  };

  // Function to handle adding repayment
  const handleAddRepayment = () => {
    if (selectedRemainingPayments) {
      const remainingPayment = selectedRemainingPayments.remaining_payment;

      // Validate that the entered amount is greater than 0 and less than or equal to the remaining payment
      if (Number(amount) > 0 && Number(amount) <= remainingPayment) {
        const existingRepaymentIndex = repaymentsArray.findIndex(
          (repayment) => repayment.id === selectedRemainingPayments.id
        );

        let updatedRepaymentsArray = [...repaymentsArray];
        let newTotalRepaymentAmount = totalRepaymentAmount;

        // If the repayment already exists, update the amount
        if (existingRepaymentIndex !== -1) {
          // Get the current amount for the existing repayment
          const currentAmount =
            updatedRepaymentsArray[existingRepaymentIndex].amount;
          // Calculate the new total by subtracting the old amount and adding the new amount
          newTotalRepaymentAmount =
            newTotalRepaymentAmount - currentAmount + Number(amount);

          updatedRepaymentsArray[existingRepaymentIndex] = {
            ...updatedRepaymentsArray[existingRepaymentIndex],
            amount: Number(amount),
          };
        } else {
          // Insert the new row data and amount into the repayments array
          const newRepayment = {
            ...selectedRemainingPayments,
            amount: Number(amount),
          };
          updatedRepaymentsArray = [...updatedRepaymentsArray, newRepayment];
          // Add the new amount to the total repayment amount
          newTotalRepaymentAmount += Number(amount);
        }

        // Update the state with the new repayments array and the total amount
        setRepaymentsArray(updatedRepaymentsArray);
        setTotalRepaymentAmount(newTotalRepaymentAmount);
        // Close the repayment dialog
        setRepaymentDialogOpen(false);
        setAmount(""); // Clear the amount input
        setrepaymentsError(""); // Clear any errors
        console.log(repaymentsArray, "repaymentsArray179");
      } else {
        // Set error message if validation fails
        setrepaymentsError(`Amount must be between 0 and ${remainingPayment}.`);
      }
    }
  };

  async function getAllMember() {
    try {
      let url = "/members/dropdown";

      const response = await get(url);
      if (response && response.data) {
        if (response.status == 200) {
          setMemberData(response.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllMember();
  }, []);

  const isAnySalesStaffPresent = cartItems.some(
    (item: any) => item.salestaffid && item.salestaffid.length > 0
  );

  const isAnyServiceStaffPresent = cartItems.some(
    (item: any) => item.service && item.service.length > 0
  );

  const handleSalesStaff = (items: any) => {
    const newStaff = {
      id: items.id,
      name: items.firstName,
      discount: "100",
      price: discountDialogOpenItem.price,
    };

    if (SalesStaffGroups.some((group: any) => group.id === items.id)) {
      return;
    }

    if (SalesStaffGroups.length >= 4) {
      // alert("Cannot add more than 3 staff members.");
      return;
    }

    const updatedGroups = [...SalesStaffGroups, newStaff];

    const discountPerStaff = (100 / updatedGroups.length).toFixed(2);

    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = (totalPrice / updatedGroups.length).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map((group) => ({
      ...group,
      discount: discountPerStaff,
      price: pricePerStaff,
    }));

    setSalesStaffGroups(updatedGroupsWithDiscountAndPrice);
    setSalesStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleServiceStaff = (items: any) => {
    const newStaff = {
      id: items.id,
      name: items.firstName,
      discount: "100",
      price: discountDialogOpenItem.price,
    };

    if (ServiceStaffGroups.some((group: any) => group.id === items.id)) {
      return;
    }

    if (ServiceStaffGroups.length >= 4) {
      // alert("Cannot add more than 3 staff members.");
      return;
    }

    const updatedGroups = [...ServiceStaffGroups, newStaff];

    const discountPerStaff = (100 / updatedGroups.length).toFixed(2);

    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = (totalPrice / updatedGroups.length).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map((group) => ({
      ...group,
      discount: discountPerStaff,
      price: pricePerStaff,
    }));

    setServiceStaffGroups(updatedGroupsWithDiscountAndPrice);
    setServiceStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleCheckout = async () => {
    setDisableBtn(true);

    let paymentMethods = [];

    // Only include payment methods that have a non-zero value
    if (cashTopup > 0) {
      paymentMethods.push({ name: "Cash", amount: cashTopup });
    }
    if (BankTransfer > 0) {
      paymentMethods.push({ name: "Bank Transfer", amount: BankTransfer });
    }
    if (CreditCard > 0) {
      paymentMethods.push({ name: "Credit Card", amount: CreditCard });
    }
    if (TouchGo > 0) {
      paymentMethods.push({ name: "Touch & Go", amount: TouchGo });
    }

    let isKIV = redeemedItems.map((item) => ({
      productId: item.productId,
      qty: item.qty,
    }));

    let isCourse = redeemedCourses.map((course: any) => ({
      serviceId: course.serviceId,
      qty: course.qty,
    }));

    let payload = {
      products: cartItems,
      isKIV: isKIV,
      isCourse: isCourse,
      payment_method: paymentMethods,
      remarks: remark,
      subtotal: total,
      finaldiscount: discount,
      cashpaid: parseFloat(cashPaidValue),
      paytopup: cashTopup,
      netprice: netAmount,
      isreceipt: printReceipt,
      memberid: selectedMember.toString(),
      outlet_id: "",
      remaining_amounts: repaymentsArray,
    };

    let receiptPrint = printReceipt;

    let url = "/cartmain/maincartdata";

    const response = await post(url, payload);

    if (response && response.data) {
      if (response.data.success) {
        // setSelectedMember('');
        getMemberDetails(selectedMember);
        if (receiptPrint) {
          url = `/cartmain/getpdf/${response.data.data.id}`;

          const responses = await get(url);

          if (responses && responses.data) {
            if (responses.data.success) {
              window.open(responses.data.data, "_blank");
              setDisableBtn(false);
            } else {
              setDisableBtn(false);
              console.log("Error in Api call ", responses.data.message);
            }
          } else {
            setDisableBtn(false);
            console.log("Response not found.");
          }
        }
        paymentMethods = [];
      } else {
        setDisableBtn(false);
        console.log("Error in Api call ", response.data.message);
      }
    } else {
      setDisableBtn(false);
      console.log("Response not found.");
    }
    setDisableBtn(false);
    resetFields();
    setVisibleCheckout(false);
    clearAllItems();
    getCart();
  };

  function resetFields() {
    setRemark("");
    setSelectedPayment("Cash");
    setTotal(0.0);
    setDiscount(0.0);
    setCashTopup(0.0);
    setNetAmount(0.0);
    setCashPaidValue("");
    setPrintReceipt(false);
    setDisableBtn(false);
    setPartialAmount(0.0);
    setIsKIV(false);
    setRedeemedItems([]);
    setRedeemedCourses([]);
    setTouchGo(0.0);
    setBankTransfer(0.0);
    setCreditCard(0.0);
    setCashTopup(0.0);
    setTotalRepaymentAmount(0.0);
  }

  const handleRedeemClick = (item: any) => {
    setSelectedKivItem(item);
    setVisibleKivQty(true);
  };

  const handleCourseRemove = (serviceId: any) => {
    setRedeemedCourses((prev: any) =>
      prev.filter((course: any) => course.serviceId !== serviceId)
    );
  };

  const handleCourseRedeemClick = (course: any) => {
    setSelectedCourseItem(course);
    setVisibleCourseQty(true);
  };

  const handleCourseSave = () => {
    if (selectedCourseItem && courseQuantity) {
      const existingCourse: any = redeemedCourses.find(
        (course: any) => course.serviceId === selectedCourseItem.serviceId
      );

      const totalQuantityRedeemed = existingCourse
        ? parseInt(existingCourse.qty) + parseInt(courseQuantity)
        : parseInt(courseQuantity);

      if (totalQuantityRedeemed > selectedCourseItem.units) {
        setCourseError(
          `Quantity cannot exceed available units. You can redeem up to ${
            selectedCourseItem.units - (existingCourse?.qty || 0)
          } more units.`
        );
        return;
      }

      if (existingCourse) {
        // Update the quantity of the existing course
        setRedeemedCourses((prev: any) =>
          prev.map((course: any) =>
            course.serviceId === selectedCourseItem.serviceId
              ? {
                  ...course,
                  qty: parseInt(course.qty) + parseInt(courseQuantity),
                }
              : course
          )
        );
      } else {
        // Add new course to redeemed courses
        setRedeemedCourses((prev: any) => [
          ...prev,
          {
            serviceId: selectedCourseItem.serviceId,
            serviceName: selectedCourseItem.serviceName,
            qty: courseQuantity,
          },
        ]);
      }

      // Reset dialog state after saving
      setVisibleCourseQty(false);
      setCourseQuantity("");
      setSelectedCourseItem(null);
      setCourseError("");
    }
  };

  const handleKivSave = () => {
    if (selectedKivItem && kivquantity) {
      const existingItem = redeemedItems.find(
        (item) => item.productId === selectedKivItem.productId
      );

      const totalQuantityRedeemed = existingItem
        ? parseInt(existingItem.qty) + parseInt(kivquantity)
        : parseInt(kivquantity);

      if (totalQuantityRedeemed > selectedKivItem.units) {
        setKivError(
          `Quantity cannot exceed available units. You can redeem up to ${
            selectedKivItem.units - (existingItem?.qty || 0)
          } more units.`
        );
        return;
      }

      if (existingItem) {
        // Update the quantity of the existing item
        setRedeemedItems((prev) =>
          prev.map((item) =>
            item.productId === selectedKivItem.productId
              ? { ...item, qty: parseInt(item.qty) + parseInt(kivquantity) }
              : item
          )
        );
      } else {
        // Add new item to redeemed items
        setRedeemedItems((prev) => [
          ...prev,
          {
            productId: selectedKivItem.productId,
            productName: selectedKivItem.productName,
            qty: kivquantity,
          },
        ]);
      }

      // Reset dialog state after saving
      setVisibleKivQty(false);
      setKivQuantity("");
      setSelectedKivItem(null);
      setKivError("");
    }
  };

  const handleKivRemove = (productId: string) => {
    // Remove the item from redeemedItems by filtering it out
    setRedeemedItems(
      redeemedItems.filter((item) => item.productId !== productId)
    );
  };

  const handleRemoveSalesStaffGroupChange = (data: any, id: string) => {
    const updatedGroups = data.salestaffid.filter(
      (group: any) => group.id !== id
    );

    const discountPerStaff = "100.00";
    const totalPrice = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );
    handleSalesStaffChange(data, updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveSalesStaffGroup = (id: string) => {
    const updatedGroups = SalesStaffGroups.filter(
      (group: any) => group.id !== id
    );

    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );

    setSalesStaffGroups(updatedGroupsWithDiscountAndPrice);
    setSalesStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveServiceStaffGroupChange = (data: any, id: string) => {
    const updatedGroups = data.service.filter((group: any) => group.id !== id);

    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );
    handleServiceStaffChange(data, updatedGroupsWithDiscountAndPrice);
  };

  const handleRemoveServiceStaffGroup = (id: string) => {
    const updatedGroups = ServiceStaffGroups.filter(
      (group: any) => group.id !== id
    );

    const numberOfStaff = updatedGroups.length;
    const discountPerStaff = "100.00";
    const totalPrice: any = discountDialogOpenItem.price;
    const pricePerStaff = parseFloat(totalPrice).toFixed(2);

    const updatedGroupsWithDiscountAndPrice = updatedGroups.map(
      (group: any) => ({
        ...group,
        discount: discountPerStaff,
        price: pricePerStaff,
      })
    );

    setServiceStaffGroups(updatedGroupsWithDiscountAndPrice);
    setServiceStaffIds(updatedGroupsWithDiscountAndPrice);
  };

  const [selectedPayments, setSelectedPayment] = useState<string | null>(
    "Cash"
  );
  const paymentModes: any[] = [
    { name: "Cash", id: "Cash" },
    { name: "Bank Transfer", id: "Bank Transfer" },
    { name: "Toch & Go", id: "Touch & Go" },
    { name: "Credit Card", id: "Credit Card" },
  ];

  interface StaffItem {
    id: string;
    firstName: string;
  }
  const [cartItem, setCartItem] = useState("");

  const confirmDiscount = (item: any) => {
    setCartItem(item);
    setDiscountDialogVisible(true);
    setDiscountDialogOpenItem(item);
    if (item.ispercentage == true) {
      setSelectedType("Percentage");
    } else if (item.ispercentage == false) {
      setSelectedType("Monetary");
    }
    setValue(item.discount);
  };

  const isStaffInSalesGroups = (staffId: any) => {
    return SalesStaffGroups.some((group: any) => group.id === staffId);
  };

  const isStaffInServiceGroups = (staffId: any) => {
    return ServiceStaffGroups.some((group: any) => group.id === staffId);
  };

  const confirmSalesStaff = (item: any) => {
    setSalesStaffGroups([]);
    setSalesStaffIds([]);
    setVisibleSalesStaff(true);
    setDiscountDialogOpenItem(item);
    setValue(item.salestaffid);
  };

  const confirmServiceStaff = (item: any) => {
    setServiceStaffGroups([]);
    setServiceStaffIds([]);
    setVisibleServiceStaff(true);
    setDiscountDialogOpenItem(item);
    setValue(item.salestaffid);
  };

  const addDiscount = (rowData: any) => {
    if (value === "") {
      handleDiscountReset(cartItem);
    } else {
      setDiscountDialogVisible(false);
      handleDiscountChange(discountDialogOpenItem);
    }
    console.log(value, "value");
  };

  const confirmPrice = async (value: any) => {
    try {
      const updatedCartItems = cartItems.map((item: any) => {
        if (item.id === itemId) {
          return {
            ...item,
            partial_payment: value,
          };
        }
        return item;
      });

      await Promise.all(
        updatedCartItems.map(
          async (item) => await updateItemById(item.id, item)
        )
      );
      setvisibleUnitPrice(false);
      setPaymentAmt("");
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };
  useEffect(() => {
    setValue(""); // Reset the value when selectedType changes
  }, [selectedType]);

  const onDeleteCancel = () => {
    setDiscountDialogVisible(false);
  };

  async function getStaffList() {
    try {
      let url = "/staff/dropdown";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          const staffList: StaffItem[] = response.data.data;
          setStaffData(staffList);
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const discountOption = [
    { name: "Monetary", id: "Monetary" },
    { name: "Percentage", id: "Percentage" },
  ];

  const handleCheckOutClose = () => {
    setCheckout(false);
  };

  const handleUnitPriceClose = () => {
    setvisibleUnitPrice(false);
    setPaymentAmt("");
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleSalesStaffClose = () => {
    const totalDiscount = salesStaffIds.reduce((total: any, group: any) => {
      return total + (parseFloat(group.discount) || 0);
    }, 0);

    if (totalDiscount > 100) {
      showAlert("Sum of Total Discount Must be 100%", "error");
      return;
    }
    setVisibleSalesStaff(false);
    handleSalesStaffChange(discountDialogOpenItem, null);
    setSalesStaffGroups([]);
    setSalesStaffIds([]);
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleServiceStaffClose = () => {
    const totalDiscount = serviceStaffIds.reduce((total: any, group: any) => {
      return total + (parseFloat(group.discount) || 0);
    }, 0);

    if (totalDiscount > 100) {
      showAlert("Sum of Total Discount Must be 100%", "error");
      return;
    }
    setVisibleServiceStaff(false);
    handleServiceStaffChange(discountDialogOpenItem, null);
    setServiceStaffGroups([]);
    setServiceStaffIds([]);
    // handlePriceChange(discountDialogOpenItem);
  };

  const handleVisibleCheckOutClose = () => {
    setVisibleCheckout(false);
  };

  const handleCategoryViewChange = (view: string) => {
    setCurrentCategoryView(view);
  };

  const handleViewChange = (view: string) => {
    setCurrentView(view);
  };

  const handleDiscountReset = async (item: any) => {
    try {
      if (item) {
        let totalPrice = parseInt(item.price) * parseInt(item.units);
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: totalPrice,
          productid: item.productid,
          type: item.type,
          discount: 0,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: totalPrice,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        setDiscountDialogVisible(false);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleChangeKIVCheck = async (
    id: string,
    value: boolean,
    item: any
  ) => {
    setIsKIV(value);
    try {
      let Item = {
        desc: item.desc,
        price: item.price,
        totalprice: item.totalprice,
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: item.remarks,
        id: item.id,
        units: item.units,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: item.partial_payment,
        isKIV: value,
        isCourse: item.isCourse,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleRemarksChange = async (id: string, value: string, item: any) => {
    try {
      let Item = {
        desc: item.desc,
        price: item.price,
        totalprice: item.totalprice,
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: value,
        id: item.id,
        units: item.units,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: item.partial_payment,
        isKIV: item.isKIV,
        isCourse: item.isCourse,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handlepayment = async (value: any) => {
    try {
      const numericValue = value.replace(/[^0-9.]/g, "");
      const numericCashPaidValue = parseFloat(numericValue) || 0;

      const totalPayments = cashTopup + BankTransfer + CreditCard + TouchGo;

      setCashPaidValue(numericValue);
      if (selectedPayments === "Cash") setCashTopup(numericCashPaidValue);
      if (selectedPayments === "Bank Transfer")
        setBankTransfer(numericCashPaidValue);
      if (selectedPayments === "Credit Card")
        setCreditCard(numericCashPaidValue);
      if (selectedPayments === "Touch & Go") setTouchGo(numericCashPaidValue);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  useEffect(() => {
    const totalPayments = cashTopup + BankTransfer + CreditCard + TouchGo;
    setDisableBtn(totalPayments !== netAmount);
  }, [visibleCheckout, totalRepaymentAmount]);

  useEffect(() => {
    const totalPayments = cashTopup + BankTransfer + CreditCard + TouchGo;

    if (totalPayments != netAmount || totalPayments > netAmount) {
      // setError(`The value cannot exceed ${netAmount}.`);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
      setError("");
    }
  }, [cashTopup, CreditCard, BankTransfer, TouchGo, totalRepaymentAmount]);

  const handleDiscountChange = async (item: any) => {
    try {
      if (item) {
        let isPer = false;
        if (selectedType == "Monetary") {
          isPer = false;
        } else if (selectedType == "Percentage") {
          isPer = true;
        }

        let totPrice = parseFloat(item.price) * item.units;
        if (isPer === true) {
          let dis = (totPrice * parseFloat(value)) / 100;
          totPrice = totPrice - dis;
        } else if (isPer === false) {
          totPrice = totPrice - parseFloat(value);
        }
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: parseFloat(item.price) * item.units,
          productid: item.productid,
          type: item.type,
          discount: value,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: isPer,
          partial_payment: totPrice,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleMainStaffChange = async (item: any) => {
    try {
      if (item) {
        let selectStaff: any = [];
        if (selectedStaff && selectedStaff.length > 0) {
          selectStaff = selectedStaff;
          selectStaff[0]["price"] = item.price;
        }
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: selectStaff,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        setSelectedStaff([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleSalesStaffChange = async (item: any, data: any) => {
    try {
      let salesStaffs = salesStaffIds;
      if (data !== null) {
        salesStaffs = data;
      }
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: salesStaffs,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        setSalesStaffIds([]);
        setSalesStaffGroups([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleServiceStaffChange = async (item: any, data: any) => {
    try {
      let serviceStaffs = serviceStaffIds;
      if (data !== null) {
        serviceStaffs = data;
      }
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: serviceStaffs,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        setServiceStaffIds([]);
        setServiceStaffGroups([]);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleAssistStaffChange = async (item: any) => {
    try {
      if (item) {
        let Item = {
          desc: item.desc,
          price: item.price,
          totalprice: item.totalprice,
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units,
          salestaffid: salesStaffIds,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: item.partial_payment,
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };
        await updateItemById(item.id, Item);
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  useEffect(() => {
    let totalPartialAmount = 0;
    cartItems.forEach((item: any) => {
      let pAmount = 0;
      if (item.partial_payment) {
        pAmount = parseFloat(item.partial_payment);
      }

      totalPartialAmount += pAmount;
    });

    setPartialAmount(totalPartialAmount);
  }, [cartItems]);

  const cartDataBodyTemplate = () => {
    return (
      <tbody>
        {cartItems &&
          cartItems.map((data: any, index) => {
            return (
              <tr key={data.id}>
                <td>{index + 1}. </td>
                <td style={{ textAlign: "left" }}>
                  {data.units} X {data.desc}
                </td>
                <td>
                  {/* <Button
                    variant="text"
                    onClick={() => {
                      confirmPrice(data);
                    }}
                  >
                  </Button> */}
                  {parseFloat(data.price).toFixed(2)}
                </td>

                <td>{parseFloat(data.totalprice).toFixed(2)}</td>
                <td>
                  <div className="flex align-items-center justify-content-center gap-2">
                    {data.ispercentage == true && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmDiscount(data);
                        }}
                      >
                        {parseFloat(data.discount).toFixed(2)}%
                      </Button>
                    )}
                    {data.ispercentage == false && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmDiscount(data);
                        }}
                      >
                        {parseFloat(data.discount).toFixed(2)}
                      </Button>
                    )}
                    {/* {data.discount > 0 && (
                      <Clear
                        className="text-red-900 cursor-pointer"
                        onClick={() => {
                          handleDiscountReset(data);
                        }}
                      />
                    )} */}
                  </div>
                </td>
                <td>
                  {/* <TextField
                    value={data.partial_payment}
                    onChange={(e) =>
                      handlepartial_paymentChange(data.id, e.target.value, data)
                    }
                    type="number"
                    fullWidth
                    rows={2}
                  /> */}
                  <Button
                    aria-hidden="true"
                    variant="text"
                    onClick={(e: any) => {
                      setvisibleUnitPrice(true);
                      setItemId(data.id);
                    }}
                  >
                    {parseFloat(data.partial_payment).toFixed(2)}
                  </Button>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data.salestaffid.length == 0 && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmSalesStaff(data);
                        }}
                      >
                        Staff
                      </Button>
                    )}
                    {data.salestaffid &&
                      data.salestaffid.length > 0 &&
                      data.salestaffid.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          <div className="col-4">
                            <Clear
                              className="text-red-900 cursor-pointer"
                              onClick={() => {
                                handleRemoveSalesStaffGroupChange(
                                  data,
                                  item.id
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data.type == "Service" && data.service.length == 0 && (
                      <Button
                        aria-hidden="true"
                        variant="text"
                        onClick={() => {
                          confirmServiceStaff(data);
                        }}
                      >
                        Staff
                      </Button>
                    )}
                    {data.type == "Service" &&
                      data.service &&
                      data.service.length > 0 &&
                      data.service.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          <div className="col-4">
                            <Clear
                              className="text-red-900 cursor-pointer"
                              onClick={() => {
                                handleRemoveServiceStaffGroupChange(
                                  data,
                                  item.id
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </td>
                {data.type === "Product" ? (
                  <td>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={data.isKIV}
                          onChange={(e: any) =>
                            handleChangeKIVCheck(
                              data.id,
                              e.target.checked,
                              data
                            )
                          }
                          color="primary"
                        />
                      }
                      label={""}
                    />
                  </td>
                ) : (
                  <td>-</td>
                )}
                <td>
                  <TextField
                    value={data.remarks}
                    onChange={(e) =>
                      handleRemarksChange(data.id, e.target.value, data)
                    }
                    fullWidth
                    multiline
                    rows={2}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  // const handleGeneratePdf = async () => {
  //   const element = document.getElementById("pdf-content");
  //   if (element) {
  //     const options = {
  //       margin: 1,
  //       filename: "generated.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //       pagebreak: { mode: ["avoid-all"] },
  //     };

  //     try {
  //       // Generate the PDF
  //       const pdf = await html2pdf()
  //         .set(options)
  //         .from(element)
  //         .outputPdf("blob");

  //       // Create a URL for the Blob
  //       const url = URL.createObjectURL(pdf);

  //       // Open the Blob URL in a new tab
  //       window.open(url, "_blank");
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //     }
  //   }
  // };

  // async function getVoucherDetails() {
  //   try {
  //     setVoucherApply(true);
  //     let url = `/voucher/apply/${voucher}`;

  //     const response = await get(url);
  //     if (response && response.data) {
  //       if (response.data.success) {
  //         if (response.data.data.isPercentage) {
  //           setVoucherType("percentage");
  //         } else {
  //           setVoucherType("fixed");
  //         }
  //         setVoucherValue(parseInt(response.data.data.voucher_value));
  //         // setBranchData(response.data.data);
  //       } else {
  //         setVoucherApply(false);
  //         setVoucherType("");
  //         setVoucherValue(0.0);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // Initialize outside the function, to track globally

  const addServiceIntoCart = async (item: any) => {
    try {
      let type = "";
      if (currentView == "1") {
        type = "Service";
      } else if (currentView == "2") {
        type = "Product";
      } else if (currentView == "3") {
        type = "MultiService";
      } else if (currentView == "4") {
        type = "Plan";
      }

      // Check if the item with the same `id` and `isCourse` status is already in the cart
      let existingItem: any = cartItems.find(
        //(cartItem: any) => cartItem.id === item.id && cartItem.isCourse === isCourse
        (cartItem: any) => cartItem.Identification === item.id + isCourse
      );
      if (existingItem) {
        // If the item with the same `isCourse` status is found, update its quantity and price
        existingItem.units += 1;
        existingItem.totalprice = (
          existingItem.units * parseFloat(item.price)
        ).toFixed(2);
        existingItem.partial_payment = existingItem.totalprice;
        await updateItemById(existingItem.id, existingItem);
      } else {
        // console.log(currentAutoIncrement,"after")
        const date = Date.now();

        // If no matching item is found, create a new item for this `isCourse` status
        let newItem = {
          desc: isCourse ? `${item.name}(Course)` : `${item.name}(Service)`,
          price: parseFloat(item.price).toFixed(2),
          totalprice: (1 * parseFloat(item.price)).toFixed(2),
          productid: item.id,
          type: type,
          discount: 0,
          remarks: "",
          id: date,
          units: 1,
          salestaffid: [],
          mainstaffid: [],
          service: [],
          ispercentage: false,
          partial_payment: parseFloat(item.price).toFixed(2),
          isKIV: true,
          isCourse: isCourse,
          Identification: item.id + isCourse, // Store the current `isCourse` status
        };

        // Add the new item to the cart
        await addItem(newItem);
      }
      // Update the cart and reset the `isCourse` flag
      await getCart();
      setIsCourse(false);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleAddToCart = async (item: any) => {
    try {
      let type = "";
      if (currentView == "1") {
        type = "Service";
      } else if (currentView == "2") {
        type = "Product";
      } else if (currentView == "3") {
        type = "MultiService";
      } else if (currentView == "4") {
        type = "Plan";
      }
      if (type === "Service") {
        setCourseModalVisible(true);
        setCartData(item);
      } else {
        let existingItem: any = cartItems.find(
          (cartItem: any) => cartItem.id === item.id
        );
        if (existingItem) {
          existingItem.units += 1;
          existingItem.totalprice = (
            existingItem.units * parseFloat(item.price)
          ).toFixed(2);
          existingItem.partial_payment = existingItem.totalprice;
          await updateItemById(item.id, existingItem);
        } else {
          let newItem = {
            desc: item.name,
            price: parseFloat(item.price).toFixed(2),
            totalprice: (1 * parseFloat(item.price)).toFixed(2),
            productid: item.id,
            type: type,
            discount: 0,
            remarks: "",
            id: item.id,
            units: 1,
            salestaffid: [],
            mainstaffid: [],
            service: [],
            ispercentage: false,
            partial_payment: parseFloat(item.price).toFixed(2),
            isKIV: true,
            isCourse: isCourse,
          };
          await addItem(newItem);
        }
        await getCart();
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const getCart = async () => {
    try {
      const result = await getItems();
      setCartItems(result);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const handleMemberChange = (selectedMemberId: string | number) => {
    getMemberDetails(selectedMemberId);
  };

  const addMore = async (item: any) => {
    try {
      let totPrice = parseFloat(item.price) * (item.units + 1);
      let totPayment = parseFloat(item.price) * (item.units + 1);
      if (item.ispercentage === true) {
        let dis = (parseFloat(item.price) * item.discount) / 100;
        totPrice = totPrice - dis;
        totPayment = totPayment - dis;
      } else if (item.ispercentage === false) {
        totPrice = totPrice - item.discount;
        totPayment = totPayment - item.discount;
      }
      let Item = {
        desc: item.desc,
        price: parseFloat(item.price).toFixed(2),
        totalprice: totPrice.toFixed(2),
        productid: item.productid,
        type: item.type,
        discount: item.discount,
        remarks: item.remarks,
        id: item.id,
        units: item.units + 1,
        salestaffid: item.salestaffid,
        mainstaffid: item.mainstaffid,
        service: item.service,
        ispercentage: item.ispercentage,
        partial_payment: totPayment.toFixed(2),
        isKIV: item.isKIV,
        isCourse: item.isCourse,
      };
      await updateItemById(item.id, Item);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const remove = async (item: any) => {
    try {
      if (item.units > 1) {
        let totPrice = parseFloat(item.price) * (item.units - 1);
        let totPayment = parseFloat(item.price) * (item.units - 1);
        if (item.ispercentage === true) {
          let dis = (parseFloat(item.price) * item.discount) / 100;
          totPrice = totPrice - dis;
          totPayment = totPayment - dis;
        } else if (item.ispercentage === false) {
          totPrice = totPrice - item.discount;
          totPayment = totPayment - item.discount;
        }

        let updatedItem = {
          desc: item.desc,
          price: parseFloat(item.price).toFixed(2),
          totalprice: totPrice.toFixed(2),
          productid: item.productid,
          type: item.type,
          discount: item.discount,
          remarks: item.remarks,
          id: item.id,
          units: item.units - 1,
          salestaffid: item.salestaffid,
          mainstaffid: item.mainstaffid,
          service: item.service,
          ispercentage: item.ispercentage,
          partial_payment: totPayment.toFixed(2),
          isKIV: item.isKIV,
          isCourse: item.isCourse,
        };

        await updateItemById(item.id, updatedItem);
      } else {
        await removeFromCart(item.id);
      }

      await getCart();
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const removeFromCart = async (id: string) => {
    try {
      await deleteItemById(id);
      await getCart();
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const getMemberDetails = async (id: any) => {
    try {
      let url = `/cartmain/maincartdata/memberId/${id}`;

      const response = await get(url);

      if (response && response.data && response.data.success) {
        // setCartItems([]);
        setRedeemedItems([]);
        setRedeemedCourses([]);

        // Set new member details
        setMemberDetails(response.data.data);
      } else {
        console.log("Error in Api call");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    try {
      let url = "/cart/getitems";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setProducts(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSalesDiscountChange = (index: number, newDiscount: string) => {
    const discountValue = parseFloat(newDiscount) || 0;
    const updatedGroups = [...SalesStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const newPrice = (originalPrice * discountValue) / 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        discount: newDiscount,
        price: newPrice.toFixed(2),
      };
    }

    setSalesStaffGroups(updatedGroups);
    setSalesStaffIds(updatedGroups);
  };

  const handleSalesPriceChange = (index: number, newPrice: string) => {
    const priceValue = parseFloat(newPrice) || 0;
    const updatedGroups = [...SalesStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const discountValue = (priceValue / originalPrice) * 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        price: newPrice,
        discount: discountValue.toFixed(2),
      };
    }
    setSalesStaffGroups(updatedGroups);
    setSalesStaffIds(updatedGroups);
  };

  const handleServiceDiscountChange = (index: number, newDiscount: string) => {
    const discountValue = parseFloat(newDiscount) || 0;
    const updatedGroups = [...ServiceStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const newPrice = (originalPrice * discountValue) / 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        discount: newDiscount,
        price: newPrice.toFixed(2),
      };
    }

    setServiceStaffGroups(updatedGroups);
    setServiceStaffIds(updatedGroups);
  };

  const handleServicePriceChange = (index: number, newPrice: string) => {
    const priceValue = parseFloat(newPrice) || 0;
    const updatedGroups = [...ServiceStaffGroups];
    if (discountDialogOpenItem) {
      const originalPrice = discountDialogOpenItem.price;
      const discountValue = (priceValue / originalPrice) * 100;

      updatedGroups[index] = {
        ...updatedGroups[index],
        price: newPrice,
        discount: discountValue.toFixed(2),
      };
    }
    setServiceStaffGroups(updatedGroups);
    setServiceStaffIds(updatedGroups);
  };

  useEffect(() => {
    handleAssistStaffChange(discountDialogOpenItem);
  }, [visibleAssistStaff]);

  useEffect(() => {
    handleMainStaffChange(discountDialogOpenItem);
  }, [visibleStaff]);

  // useEffect(() => {
  //   handleServiceStaffChange(discountDialogOpenItem);
  // }, [visibleServiceStaff]);

  // useEffect(() => {
  //   handleDiscountChange(discountDialogOpenItem);
  // }, [discountDialogVisible]);

  // useEffect(() => {
  //   handlePriceChange(discountDialogOpenItem);
  // }, [visibleUnitPrice]);

  // useEffect(() => {
  //   setChildStyle("fixed");

  //   return () => {
  //     setChildStyle("");
  //   };
  // }, [setChildStyle]);

  useEffect(() => {
    const product = products.find((p: any) => p.id === currentView);
    const categories: any = product
      ? product.categories.map((category: any) => ({
          id: category.id,
          name: category.categoryname,
        }))
      : [];
    if (categories.length > 0) {
      let allCategory = {
        id: "all",
        name: "All",
      };
      categories.unshift(allCategory);
      setCategoryNamesByMainCategory(categories);
      handleCategoryViewChange("all");
    } else {
      setCategoryNamesByMainCategory([]);
    }
  }, [currentView]);

  useEffect(() => {
    let result: any = [];
    for (let product of products) {
      if (product.id == currentView) {
        for (let category of product.categories) {
          if (currentCategoryView === "all") {
            result.push({
              id: category.id,
              name: category.categoryname,
              items: category.items.map((item: any) => ({
                id: item.id,
                name: item.productname,
                price: item.price,
              })),
            });
          } else if (category.id === currentCategoryView) {
            result.push({
              id: category.id,
              name: category.categoryname,
              items: category.items.map((item: any) => ({
                id: item.id,
                name: item.productname,
                price: item.price,
              })),
            });
          }
        }
      }
    }
    setProductByCategory(result);
  }, [currentView, currentCategoryView]);

  useEffect(() => {
    let newTotal = cartItems.reduce(
      (acc, item: any) => acc + parseFloat(item.totalprice),
      0
    );

    // let finalTotal = newTotal;

    // if (voucherType == "percentage") {
    //   let dis = (newTotal * voucherValue) / 100;
    //   finalTotal = newTotal - dis;
    // } else if (voucherType == "fixed") {
    //   finalTotal = newTotal - voucherValue;
    // }

    setTotal(newTotal);
    // setFinalTotal(finalTotal);
  }, [cartItems, voucherValue, total, voucherType, voucherApply]);

  useEffect(() => {
    let totalDiscount = 0;

    cartItems.forEach((item: any) => {
      let discount = 0;
      if (!item.ispercentage) {
        discount = parseFloat(item.discount);
      } else if (item.ispercentage) {
        discount =
          ((parseFloat(item.discount) * parseInt(item.units)) / 100) *
          parseFloat(item.price);
      }

      totalDiscount += discount;
    });

    setDiscount(totalDiscount);
  }, [cartItems, total, discount]);

  useEffect(() => {
    let finalTotal = total;
    let totalPartialAmount = partialAmount;

    // let netTotal = finalTotal - totalPartialAmount;
    let netTotal = totalPartialAmount + totalRepaymentAmount;
    //netTotal -= cashTopup;
    setNetAmount(netTotal);
  }, [total, discount, cashTopup, partialAmount]);

  useEffect(() => {
    const handleWheel = (event: any) => {
      event.preventDefault();
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel);
    }
    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getProducts();
      await getCart();
    };

    fetchData();
    getStaffList();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      let mainCategories: any = products.map((product: any) => ({
        id: product.id,
        name: product.mainCategoryName,
        disabled: product.categories.length > 0 ? false : true,
      }));
      setMainCategory(mainCategories);
      const enabledCategories = mainCategories.filter(
        (category: any) => !category.disabled
      );
      if (enabledCategories.length > 0) {
        const initialCategory = enabledCategories[0].id;
        setCurrentView(initialCategory);
        handleViewChange(initialCategory);
      }
    }
  }, [products]);

  return (
    <div className="dashboard-margin">
      <div className="grid">
        <div className="col-8">
          <div className="grid sticky-box-section">
            <div className="col-12">
              <div
                className="flex align-items-center gap-2 flex-nowrap w-full white-space-nowrap overflow-x-auto"
                ref={scrollContainerRef}
              >
                {mainCategory.map((category: any) => (
                  <Button
                    variant="contained"
                    id={category.id}
                    key={category.id}
                    className={`calendar-btn ${
                      currentView == category.id ? "selected" : ""
                    }`}
                    disabled={category.disabled}
                    onClick={() => handleViewChange(category.id)}
                  >
                    {category.name}
                  </Button>
                ))}
                <i className="ri-search-line text-2xl"></i>
              </div>
            </div>
          </div>
          <div className="grid sticky-box-category">
            <div className="col-12">
              <div
                className="flex align-items-center gap-2 flex-nowrap w-full white-space-nowrap overflow-x-auto"
                ref={scrollContainerRef}
              >
                {categoryNamesByMainCategory.map((category: any) => (
                  <Button
                    variant="contained"
                    id={category.id}
                    key={category.id}
                    className={`calendar-btn ${
                      currentCategoryView == category.id ? "selected" : ""
                    }`}
                    onClick={() => handleCategoryViewChange(category.id)}
                  >
                    {category.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="grid mt-2 pb-6 product-show-h">
            {productByCategory.map((category: any) => (
              <div className="col-12" key={category.id} id={category.id}>
                <span className="form-label text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                  {category.name}
                </span>
                <div className="grid mt-2 ">
                  {category.items.map((item: any) => (
                    <div className="col-3" key={item.id} id={item.id}>
                      <div
                        className="cursor-pointer flex flex-column gap-1 border-3 border-black border-round-lg p-3 bg-white"
                        onClick={() => handleAddToCart(item)}
                      >
                        <span className="form-label text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                          {item.name}
                        </span>
                        <span>{item.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-4">
          <TextField
            select
            label="Member"
            value={selectedMember}
            onChange={(e) => {
              setSelectedMember(e.target.value as string);
              getMemberDetails(e.target.value as string);
              handleMemberChange(e.target.value);
              // if (e.target.value) {
              //   setMemberError("");
              // }
            }}
            fullWidth
            error={!!memberError}
            helperText={memberError}
            margin="normal"
          >
            {memberData.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.label}
              </MenuItem>
            ))}
          </TextField>
          {memberDetails && memberDetails.id && (
            <>
              <div className="grid box-shadow-common">
                <div className="col-12 bg-white p-3">
                  <div className="grid">
                    <div className="col-6">Name</div>
                    <div className="col-6">{memberDetails.name}</div>
                  </div>
                  <div className="grid">
                    <div className="col-6">Gender</div>
                    <div className="col-6">{memberDetails.gender}</div>
                  </div>
                  <div className="grid">
                    <div className="col-6">Mobile Number</div>
                    <div className="col-6">{memberDetails.mobile}</div>
                  </div>
                  <div className="grid">
                    <div className="col-6">Date of Birth</div>
                    <div className="col-6">{memberDetails.dob}</div>
                  </div>
                  <hr style={{ borderTop: "1px solid black" }} />
                  <div className="grid">
                    <div className="col-6">Vouchers</div>
                    <div className="col-6">{memberDetails.vouchers}</div>
                  </div>
                  <div className="grid">
                    <div className="col-6">Reward Points</div>
                    <div className="col-6">{memberDetails.rewardpoints}</div>
                  </div>
                  <div className="grid">
                    <div className="col-6">Remaining Payment</div>
                    <div className="col-6">
                      {memberDetails.total_remaining_payment}
                    </div>
                  </div>
                  <hr style={{ borderTop: "1px solid black" }} />
                  <div className="grid flex-column">
                    <h3 style={{ fontWeight: "bold", fontSize: 14 }}>In KIV</h3>
                    {/* <div className="grid"> */}
                    {memberDetails.cartDetails &&
                      memberDetails.cartDetails.length > 0 &&
                      memberDetails.cartDetails.map((item: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="grid">
                            <div className="" style={{ marginLeft: 10 }}>
                              {item.productName}
                            </div>
                            <div> ({item.units})</div>
                          </div>
                          <Button
                            variant="text"
                            onClick={() => handleRedeemClick(item)}
                          >
                            redeem
                          </Button>
                        </div>
                      ))}
                    {/* </div> */}
                  </div>

                  {/* New Course Section */}
                  <div
                    className="grid flex-column"
                    style={{ marginTop: "20px" }}
                  >
                    <h3 style={{ fontWeight: "bold", fontSize: 14 }}>
                      Courses
                    </h3>
                    {memberDetails &&
                      memberDetails.courseDetails &&
                      ((memberDetails &&
                        memberDetails.total_remaining_payment) ||
                        0) === 0 &&
                      memberDetails.courseDetails.length > 0 &&
                      memberDetails.courseDetails.map((course: any) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          key={course.serviceId}
                        >
                          <div className="grid">
                            <div className="" style={{ marginLeft: 10 }}>
                              {course.serviceName}
                            </div>
                            <div> ({course.units})</div>
                          </div>
                          <Button
                            variant="text"
                            onClick={() => handleCourseRedeemClick(course)}
                          >
                            redeem
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* <div className="grid box-shadow-common">
            <div className="col-12 flex align-items-center justify-content-between bg-white p-3">
              <span>No membership applied yet.</span>
              <span className="text-primary">Search</span>
            </div>
          </div> */}
          {/* <div className="grid mt-3 box-shadow-common">
            <div className="col-12 flex align-items-center justify-content-between bg-white p-3">
              <div className="p-inputgroup flex-1">
                <InputText
                  placeholder="Add voucher"
                  onChange={(e) => setVoucher(e.target.value)}
                />
                <span
                  className="p-inputgroup-addon text-red-600 font-bold cursor-pointer"
                  onClick={getVoucherDetails}
                >
                  Apply
                </span>
              </div>
            </div>
          </div> */}

          <div className="grid mt-3 box-shadow-common">
            <div className="col-12 flex align-items-center justify-content-center bg-white p-3 border-bottom-2 border-300">
              <span className="form-label">Seleted items</span>
            </div>
          </div>
          <div className="grid mt-0">
            {cartItems && cartItems.length > 0 ? (
              cartItems.map((item: any) => (
                <div
                  className="col-12 bg-white p-3 border-bottom-1 border-300"
                  key={item.id}
                  id={item.id}
                >
                  <div className="grid m-0">
                    <div className="col-7 flex flex-column gap-1 p-0">
                      <span className="form-label">
                        {item.units} X {item.desc}
                      </span>
                      <span className="form-label">RM{item.price}</span>
                    </div>
                    <div className="col-5 flex align-items-center justify-content-end gap-1 p-0">
                      <IconButton color="success" onClick={() => addMore(item)}>
                        <Add />
                      </IconButton>
                      <IconButton color="primary" onClick={() => remove(item)}>
                        <Remove />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => removeFromCart(item.id)}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 bg-white p-3 border-bottom-1 border-300 flex justify-content-center p-2">
                <span className="form-label">No Item Selected For Cart.</span>
              </div>
            )}
          </div>

          {((cartItems && cartItems.length > 0) ||
            (redeemedItems && redeemedItems.length > 0) ||
            ((memberDetails && memberDetails.total_remaining_payment) || 0) >
              0 ||
            (redeemedCourses && redeemedCourses.length > 0)) && (
            <div className="grid totalamount-text bg-common">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#4CAF50",
                  padding: 2,
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  RM{total.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  p: 0,
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setVisibleCheckout(true)}
                  disabled={!selectedMember}
                >
                  {!selectedMember
                    ? "Please selecte member first"
                    : "Proceed to checkout"}
                </Button>
              </Box>
              <Dialog open={checkout} onClose={handleCheckOutClose}>
                <DialogTitle>Add Payment Details</DialogTitle>
                <DialogContent>
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <TextField
                        label="Account Holder Name"
                        id="account-holder"
                        placeholder="Enter your name"
                        className="p-1"
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <TextField
                        label="Card Number"
                        id="card-number"
                        placeholder="Enter your card number"
                        className="p-1"
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                        variant="outlined"
                      />
                    </div>

                    <div
                      style={{ marginTop: "10px" }}
                      className="flex flex-row flex-1 align-items-center justify-content-between"
                    >
                      <div style={{}} className="flex flex-initial flex-column">
                        <TextField
                          label="Month/year"
                          id="month-year"
                          placeholder="12/56"
                          className="p-1"
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            marginTop: "10px",
                          }}
                          variant="outlined"
                        />
                      </div>
                      <div style={{}} className="flex flex-initial flex-column">
                        <TextField
                          label="CVV"
                          id="cvv"
                          placeholder="***"
                          className="p-1"
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            marginTop: "10px",
                          }}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <Button variant="contained" className="add-button">
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleCheckOutClose}
                        className="btn-close ml-2 "
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={visibleSalesStaff}
                PaperProps={{
                  sx: {
                    width: "68vw",
                    maxWidth: "none",
                  },
                }}
                onClose={() => {
                  if (!visibleSalesStaff) return;
                  setVisibleSalesStaff(false);
                }}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Choose Sales staff
                  </label>
                  <div className="col-12">
                    <label>
                      You can choose at most 4 sales staff for this item.
                    </label>
                  </div>
                  <div className="grid mt-2">
                    <div className="col-4">
                      {staffData.map((items: StaffItem) => (
                        <Button
                          variant="contained"
                          key={items.id}
                          onClick={() => {
                            handleSalesStaff(items);
                          }}
                          disabled={isStaffInSalesGroups(items.id)}
                          className="p-button-text ml-2 mb-2 add-button"
                        >
                          {items.firstName}
                        </Button>
                      ))}
                    </div>
                    <div className="col-8">
                      {SalesStaffGroups.map((group: any, index: any) => (
                        <div
                          key={index}
                          className="grid col-12 align-items-center"
                        >
                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <TextField
                                id={`name-${index}`}
                                disabled
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                value={group.name}
                                variant="outlined"
                              />
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-3">
                              <div className="p-inputgroup flex flex-1">
                                <TextField
                                  id={`discount-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleSalesDiscountChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.discount}
                                  variant="outlined"
                                />
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          )}

                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <div className="p-inputgroup flex flex-1">
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  RM
                                </span>
                                <TextField
                                  id={`price-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleSalesPriceChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.price}
                                  variant="outlined"
                                />
                              </div>
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-1">
                              {staffGroups && (
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() =>
                                    handleRemoveSalesStaffGroup(group.id)
                                  }
                                >
                                  X
                                </span>
                              )}
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-12 py-0">
                              <label>
                                Total amount is after discount. (RM
                                {group.price})
                              </label>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <Button
                        variant="contained"
                        className="add-button"
                        onClick={handleSalesStaffClose}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={visibleServiceStaff}
                PaperProps={{
                  sx: {
                    width: "68vw",
                    maxWidth: "none",
                  },
                }}
                onClose={() => {
                  if (!visibleServiceStaff) return;
                  setVisibleServiceStaff(false);
                }}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Choose Service staff
                  </label>
                  <div className="col-12">
                    <label>
                      You can choose at most 4 service staff for this item.
                    </label>
                  </div>
                  <div className="grid mt-2">
                    <div className="col-4">
                      {staffData.map((items: StaffItem) => (
                        <Button
                          variant="contained"
                          key={items.id}
                          onClick={() => {
                            handleServiceStaff(items);
                          }}
                          disabled={isStaffInServiceGroups(items.id)}
                          className="p-button-text ml-2 mb-2 add-button"
                        >
                          {items.firstName}
                        </Button>
                      ))}
                    </div>
                    <div className="col-8">
                      {ServiceStaffGroups.map((group: any, index: any) => (
                        <div
                          key={index}
                          className="grid col-12 align-items-center"
                        >
                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <TextField
                                id={`name-${index}`}
                                disabled
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                value={group.name}
                                variant="outlined"
                              />
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-3">
                              <div className="p-inputgroup flex flex-1">
                                <TextField
                                  id={`discount-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleServiceDiscountChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.discount}
                                  variant="outlined"
                                />
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  %
                                </span>
                              </div>
                            </div>
                          )}

                          {group.id && group.id.length > 0 && (
                            <div className="col-4">
                              <div className="p-inputgroup flex flex-1">
                                <span
                                  className="p-inputgroup-addon"
                                  style={{
                                    padding: "16.5px 14px",
                                  }}
                                >
                                  RM
                                </span>
                                <TextField
                                  id={`price-${index}`}
                                  style={{
                                    width: "95%",
                                    borderRadius: "0px",
                                  }}
                                  onChange={(e) =>
                                    handleServicePriceChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={group.price}
                                  variant="outlined"
                                />
                              </div>
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-1">
                              {staffGroups && (
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() =>
                                    handleRemoveServiceStaffGroup(group.id)
                                  }
                                >
                                  X
                                </span>
                              )}
                            </div>
                          )}
                          {group.id && group.id.length > 0 && (
                            <div className="col-12 py-0">
                              <label>
                                Total amount is after discount. (RM
                                {group.price})
                              </label>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-12">
                      <Button
                        variant="contained"
                        className="add-button"
                        onClick={handleServiceStaffClose}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={visibleCheckout}
                PaperProps={{
                  sx: {
                    width: "78vw",
                    maxWidth: "none",
                  },
                }}
                onClose={handleVisibleCheckOutClose}
              >
                <DialogTitle>
                  Checkout confirmation
                  <IconButton
                    aria-label="close"
                    onClick={handleVisibleCheckOutClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div className="grid">
                    <div className="col-12">
                      <label className="form-label">Selected items</label>
                      <table className="cart-table mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Unit Price (RM)</th>
                            <th>Total Sales (RM)</th>
                            <th>Discount</th>
                            <th>Total Amt To Pay (RM)</th>
                            <th
                              style={{
                                textAlign: isAnySalesStaffPresent
                                  ? "left"
                                  : "center",
                              }}
                            >
                              Sales
                            </th>
                            <th
                              style={{
                                textAlign: isAnyServiceStaffPresent
                                  ? "left"
                                  : "center",
                              }}
                            >
                              Service
                            </th>
                            <th>KIV / Taken</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        {cartDataBodyTemplate()}
                      </table>
                    </div>
                  </div>

                  {/* Redeem KIV Products Section */}
                  <div className="grid mt-4">
                    <div className="col-12">
                      <label className="form-label">Redeem KIV Products</label>
                      <table className="cart-table mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {redeemedItems.length > 0 ? (
                            redeemedItems.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.productName}</td>
                                <td>{item.qty}</td>
                                <td>
                                  {/* Remove button */}
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() =>
                                      handleKivRemove(item.productId)
                                    }
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={3} style={{ textAlign: "center" }}>
                                No items redeemed yet.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Redeem Courses Section */}
                  <div className="grid mt-4">
                    <div className="col-12">
                      <label className="form-label">Redeem Courses</label>
                      <table className="cart-table mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Course Name</th>
                            <th>Quantity</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {redeemedCourses.length > 0 ? (
                            redeemedCourses.map((course: any, index: any) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{course.serviceName}</td>
                                <td>{course.qty}</td>
                                <td>
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() =>
                                      handleCourseRemove(course.serviceId)
                                    }
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={3} style={{ textAlign: "center" }}>
                                No courses redeemed yet.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="grid mt-4">
                    <div className="col-12">
                      <label className="form-label">Remaining Payments</label>
                      <table className="cart-table mt-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Bill Number</th>
                            <th>Remaining Payment (RM)</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {memberDetails &&
                          memberDetails.remaining_payment.length > 0 ? (
                            memberDetails.remaining_payment.map(
                              (item: any, index: number) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.billnumber}</td>
                                  <td>{item.remaining_payment}</td>
                                  <td>
                                    <Button
                                      variant="text"
                                      onClick={() => handleRepaymentClick(item)}
                                    >
                                      Repayment
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={5} style={{ textAlign: "center" }}>
                                No remaining payments.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="grid mt-3">
                    <div className="col-6">
                      <div className="grid">
                        <div className="col-12">
                          <label className="form-label">Payment method</label>
                          <TextField
                            id={`payment-method`}
                            select
                            value={selectedPayments}
                            onChange={(e) => setSelectedPayment(e.target.value)}
                            fullWidth
                            InputProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              },
                            }}
                          >
                            {paymentModes.map((staff) => (
                              <MenuItem key={staff.id} value={staff.id}>
                                {staff.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-12">
                          <label className="form-label">
                            {selectedPayments} paid
                            <span className="font-medium">
                              (To calculate charge)
                            </span>
                          </label>
                          <TextField
                            id="cash-paid"
                            type="number"
                            style={{
                              width: "100%",
                              borderRadius: "4px",
                            }}
                            value={
                              selectedPayments === "Cash"
                                ? cashTopup === 0
                                  ? ""
                                  : cashTopup // Display empty string instead of 0
                                : selectedPayments === "Credit Card"
                                ? CreditCard === 0
                                  ? ""
                                  : CreditCard
                                : selectedPayments === "Bank Transfer"
                                ? BankTransfer === 0
                                  ? ""
                                  : BankTransfer
                                : selectedPayments === "Touch & Go"
                                ? TouchGo === 0
                                  ? ""
                                  : TouchGo
                                : ""
                            }
                            onChange={(e) => handlepayment(e.target.value)}
                            variant="outlined"
                            error={!!error} // Shows an error message if validation fails
                            helperText={error}
                          />
                        </div>
                      </div>

                      <div className="grid">
                        <div className="col-12">
                          <label className="form-label">Remark</label>
                          <TextField
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                          />
                          <label>
                            This remarks will be shown in customer receipt.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="grid mt-3">
                        <div className="col-6 flex gap-2 flex-column align-items-end">
                          <label className="font-semibold">Subtotal</label>
                          <label className="font-semibold">Discount</label>
                          <label className="font-semibold">
                            Total Amt To Pay (RM)
                          </label>
                          <label className="font-semibold">
                            Repayment Amount
                          </label>
                          {cashTopup > 0 && (
                            <label className="font-semibold">
                              Pay with cash
                            </label>
                          )}
                          {BankTransfer > 0 && (
                            <label className="font-semibold">
                              Bank Transfer
                            </label>
                          )}
                          {CreditCard > 0 && (
                            <label className="font-semibold">Credit Card</label>
                          )}
                          {TouchGo > 0 && (
                            <label className="font-semibold">Touch & Go</label>
                          )}
                          <label className="font-semibold">
                            Remaining To Pay
                          </label>
                          <label className="text-3xl font-bold">
                            Net amount
                          </label>
                        </div>
                        <div className="col-6 flex gap-2 flex-column align-items-start">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="font-semibold">
                              {total?.toFixed(2)}
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="font-semibold">
                              {discount?.toFixed(2)}
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="font-semibold">
                              {partialAmount?.toFixed(2)}
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="font-semibold">
                              {totalRepaymentAmount?.toFixed(2)}
                            </label>
                          </div>

                          {cashTopup > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label className="font-semibold">
                                {cashTopup?.toFixed(2)}
                              </label>
                              <IconButton
                                aria-label="reset"
                                onClick={() => setCashTopup(0)} // Reset Cash Topup value
                                sx={{
                                  padding: "0px",
                                  marginLeft: "8px",
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          )}
                          {BankTransfer > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label className="font-semibold">
                                {BankTransfer?.toFixed(2)}
                              </label>
                              <IconButton
                                aria-label="reset"
                                onClick={() => setBankTransfer(0)} // Reset Bank Transfer value
                                sx={{
                                  padding: "0px",
                                  marginLeft: "8px",
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          )}
                          {CreditCard > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label className="font-semibold">
                                {CreditCard?.toFixed(2)}
                              </label>
                              <IconButton
                                aria-label="reset"
                                onClick={() => setCreditCard(0)} // Reset Credit Card value
                                sx={{
                                  padding: "0px",
                                  marginLeft: "8px",
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          )}
                          {TouchGo > 0 && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label className="font-semibold">
                                {TouchGo?.toFixed(2)}
                              </label>
                              <IconButton
                                aria-label="reset"
                                onClick={() => setTouchGo(0)} // Reset Touch & Go value
                                sx={{
                                  padding: "0px",
                                  marginLeft: "8px",
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          )}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="font-semibold">
                              {(
                                netAmount -
                                cashTopup -
                                BankTransfer -
                                CreditCard -
                                TouchGo
                              )?.toFixed(2)}
                            </label>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label className="text-3xl font-bold">
                              {netAmount?.toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-12">
                      <hr />
                      <div className="flex align-items-center mt-3">
                        <Checkbox
                          id="ingredient1"
                          checked={printReceipt}
                          onChange={(e: any) =>
                            setPrintReceipt(e.target.checked)
                          }
                        />
                        <label htmlFor="ingredient1" className="ml-2">
                          Print receipt
                        </label>
                      </div>

                      {/* <Button
                        variant="contained"
                        // onClick={() => setclockInDialogVisible(true)}
                        onClick={handleCheckout}
                        disabled={disableBtn}
                        className="p-button-text w-full mt-2"
                      >
                        Confirm checkout
                      </Button> */}
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  {/* <div> */}
                  {/* <Button
                      variant="outlined"
                      onClick={handleVisibleCheckOutClose}
                      style={{ width: "45%" }}
                    >
                      Close
                    </Button> */}

                  <Button
                    variant="contained"
                    onClick={handleCheckout}
                    disabled={disableBtn}
                    style={{ width: "45%" }}
                    className="w-full mt-2"
                  >
                    Confirm checkout
                  </Button>
                  {/* </div> */}
                </DialogActions>
              </Dialog>

              <Dialog
                open={discountDialogVisible}
                PaperProps={{
                  sx: {
                    width: "60vw",
                    maxWidth: "none",
                  },
                }}
                onClose={onDeleteCancel}
              >
                <DialogTitle>Change Unit Discount</DialogTitle>
                <DialogContent>
                  <div className="grid col-12">
                    <div className="col-6">
                      <TextField
                        label="Type"
                        id={`type`}
                        select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        fullWidth
                        className="mt-1"
                        InputProps={{
                          style: {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      >
                        {discountOption.map((staff) => (
                          <MenuItem key={staff.id} value={staff.id}>
                            {staff.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="col-6">
                      <TextField
                        label={`Value ${
                          selectedType === "Monetary" ? "(RM)" : "(%)"
                        }`}
                        id="value"
                        className="mt-1"
                        value={value}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          // If inputValue is empty, set it to "0"
                          if (!inputValue) {
                            inputValue = "0";
                          }
                          if (selectedType === "Percentage") {
                            if (Number(inputValue) > 100) {
                              inputValue = "100";
                            }
                          }

                          setValue(inputValue);
                        }}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          setValue(e.target.value);
                        }}
                        style={{
                          width: "100%",
                          borderRadius: "4px",
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <Checkbox
                      onChange={(e: any) => setChecked(e.target.checked!)}
                      checked={checked}
                    ></Checkbox>
                    <label className="ml-2">
                      Apply the price change to all same item.
                    </label>
                  </div> */}
                  <div className="col-12 mt-2">
                    <Button
                      variant="contained"
                      className="add-button"
                      onClick={addDiscount}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      className="add-button ml-2"
                      onClick={() => handleDiscountReset(cartItem)}
                    >
                      Reset
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={visibleUnitPrice}
                PaperProps={{
                  sx: {
                    width: "30vw",
                    maxWidth: "none",
                  },
                }}
                onClose={handleUnitPriceClose}
              >
                <DialogContent>
                  <label className="form-label col-12">
                    Change Payment Amt (RM)
                  </label>
                  <div className="col-12 ">
                    <TextField
                      id="Payment"
                      style={{
                        width: "100%",

                        borderRadius: "4px",
                      }}
                      value={paymentAmt}
                      onChange={(e) => setPaymentAmt(e.target.value)}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");

                        setPaymentAmt(e.target.value);
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <Button
                      variant="contained"
                      className="add-button"
                      onClick={() => confirmPrice(paymentAmt)}
                    >
                      Save
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>

        <Dialog
          open={visibleCourseQty}
          PaperProps={{
            sx: {
              width: "30vw",
              maxWidth: "none",
            },
          }}
          onClose={() => setVisibleCourseQty(false)}
        >
          <DialogContent>
            <label className="form-label col-12">Redeem Course</label>
            <div className="col-12 ">
              <TextField
                id="course-qty"
                style={{
                  width: "100%",
                  borderRadius: "4px",
                }}
                value={courseQuantity}
                onChange={(e) => setCourseQuantity(e.target.value)}
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                variant="outlined"
                error={!!courseError} // Shows error styling if validation fails
                helperText={courseError} // Displays the error message
              />
            </div>
            <div className="col-12 mt-2">
              <Button
                variant="contained"
                className="add-button"
                onClick={handleCourseSave}
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={visibleKivQty}
          PaperProps={{
            sx: {
              width: "30vw",
              maxWidth: "none",
            },
          }}
          onClose={() => setVisibleKivQty(false)}
        >
          <DialogContent>
            <label className="form-label col-12">Redeem Product</label>
            <div className="col-12 ">
              <TextField
                id="qty"
                style={{
                  width: "100%",

                  borderRadius: "4px",
                }}
                value={kivquantity}
                onChange={(e) => setKivQuantity(e.target.value)}
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                variant="outlined"
                error={!!kiverror}
                helperText={kiverror}
              />
            </div>
            <div className="col-12 mt-2">
              <Button
                variant="contained"
                className="add-button"
                onClick={handleKivSave}
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={courseModalVisible}
          PaperProps={{
            sx: {
              width: "60vw",
              maxWidth: "none",
            },
          }}
          onClose={() => setCourseModalVisible(false)}
        >
          <DialogTitle>Select Course or Treatment</DialogTitle>
          <DialogContent>
            <div className="grid col-12">
              <div className="col-12">
                <Checkbox
                  onChange={(e: any) => setIsCourse(true)}
                  checked={isCourse}
                />
                <label className="ml-2">Course</label>
              </div>
              <div className="col-12">
                <Checkbox
                  onChange={(e: any) => setIsCourse(false)}
                  checked={!isCourse}
                />
                <label className="ml-2">Treatment</label>
              </div>
            </div>
            <Button
              variant="contained"
              className="add-button"
              onClick={() => {
                addServiceIntoCart(cartData);
                setCourseModalVisible(false);
              }}
            >
              Save
            </Button>
          </DialogContent>
        </Dialog>

        {/* Repayment Dialog */}
        <Dialog
          open={repaymentDialogOpen}
          onClose={() => setRepaymentDialogOpen(false)}
        >
          <DialogTitle>
            Repayment
            <IconButton
              aria-label="close"
              onClick={() => setRepaymentDialogOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              error={!!repaymenterror} // Highlight the text field if there's an error
              helperText={repaymenterror} // Display error message below the input field
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddRepayment} variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CartPage;
