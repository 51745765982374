import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  InputAdornment,
  Stack,
  Typography,
  Card,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { get, put } from "../../services/api.service";
import CustomDataTable from "src/components/CustomDataTable";

const InventoryPageOutlet: React.FC = () => {
  const [stockData, setStockData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);

  //dialogbox for comformation
  const [openDialog, setOpenDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState<
    "Accepted" | "Rejected" | null
  >(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [disabled, setDisabled] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const columns = [
    { label: "#", field: "index" },
    { label: "Date", field: "created_at" },
    { label: "Product Name", field: "productName" },
    { label: "Stock Quantity", field: "assignstock" },
    { label: "Remark", field: "remarks" },
    { label: "Status", field: "status" },
    { label: "action", field: "" },
  ];

  function resetFields() {
    setOpenDialog(false);
    setCurrentAction(null);
    setSelectedRow(null);
    setDisableBtn(false);
  }

  const handleAcceptClick = (rowData: any) => {
    setDisableBtn(false);
    setCurrentAction("Accepted");
    setSelectedRow(rowData);
    setOpenDialog(true);
  };

  const handleRejectClick = (rowData: any) => {
    setDisableBtn(false);
    setCurrentAction("Rejected");
    setSelectedRow(rowData);
    setOpenDialog(true);
  };

  async function getAllStock() {
    try {
      const url = "/assignstock/getall";
      const response = await get(url);
      if (response?.data?.success) {
        setStockData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function Updateassignstock() {
    setDisableBtn(true);
    try {
      let Id = selectedRow.id;
      const url = "/assignstock/" + Id;
      let body = {
        stockid: selectedRow.stockid,
        merchant_id: "",
        outlet_id: selectedRow.outlet_id,
        assignstock: selectedRow.assignstock,
        remarks: selectedRow.remarks,
        status: currentAction,
      };

      const response = await put(url, body);

      if (response && response.data) {
        if (response.data.success) {
          resetFields();
          getAllStock();
          setDisabled(true);
          setOpenDialog(false);
        } else {
          setDisabled(true);
          setOpenDialog(false);
          getAllStock();
          console.log("Error in Api call ", response.data.message);
        }
      } else {
        setDisabled(true);
        setOpenDialog(false);
        getAllStock();
        console.log("Response not found.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAllStock();
    // getBranchList();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        stockData?.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(stockData);
    }
  }, [searchQuery, stockData]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Inventory</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search stock"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        isStockAction={true}
        handleAcceptClick={(rowData: any) => handleAcceptClick(rowData)}
        handleRejectClick={(rowdata: any) => handleRejectClick(rowdata)}
        openAddNew={() => {}}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {currentAction === "Accepted" ? "Confirm Accept" : "Confirm Reject"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentAction === "Accepted"
              ? "Are you sure you want to accept this stock?"
              : "Are you sure you want to reject this stock?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            disabled={disableBtn}
            onClick={() => {
              if (currentAction === "Accepted") {
                Updateassignstock();
              } else {
                Updateassignstock();
              }
            }}
            color="primary"
          >
            {currentAction === "Accepted" ? "Accepted" : "Rejected"}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default InventoryPageOutlet;
