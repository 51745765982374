import { TableCell, TableRow } from "@mui/material";
import AppointmentCell from "./AppointmentCell"; // Importing the AppointmentCell component

const TimeSlotRow = ({
  time,
  staffMembers,
  appointments,
  isStaffDisabled,
  onAppointmentClick,
}: any) => {
  return (
    <TableRow>
      <TableCell style={{ width: "100px" }}>{time}</TableCell>
      {/* First column shows the time slot */}
      {staffMembers.map((staff: any) => (
        <TableCell
          key={staff.id}
          style={{
            width: "150px",
            minHeight: "100px",
            backgroundColor: isStaffDisabled(staff.id) ? "#D3D3D3" : "inherit",
            color: isStaffDisabled(staff.id) ? "gray" : "inherit",
          }}
        >
          <AppointmentCell
            time={time}
            staff={staff}
            appointments={appointments}
            isStaffDisabled={isStaffDisabled}
            onAppointmentClick={onAppointmentClick}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TimeSlotRow;
