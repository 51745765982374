import { Card, Typography } from "@mui/material";

const AppointmentDetailCard = ({ appointment, viewAppointment }: any) => {
  return (
    <Card
      style={{
        padding: "10px",
        backgroundColor: "#ffffff",
        borderLeft: "5px solid #3f51b5",
      }}
      onClick={viewAppointment}
    >
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", color: "#3f51b5" }}
      >
        {appointment.staff}
      </Typography>
      <Typography
        variant="body2"
        style={{ fontWeight: "bolder", color: "#000" }}
      >
        {appointment.customerName}
      </Typography>
      <Typography variant="body2">{appointment.service}</Typography>
    </Card>
  );
};

export default AppointmentDetailCard;
