/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  Stack,
  Typography,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { Add as AddIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CustomClendar from "./CustomCalendar";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axiosService from "src/services/axios";
import { Merchant } from "src/types/merchant-interface";
import { CONSTANTS } from "src/constants";
import { RecordTypes } from "src/types/enum";
import { get, post } from "src/services/api.service";
import AppointmentDetailsModal from "./AppointmentDetailsModal";

interface ServiceErrors {
  [index: number]: string;
}

type Errors = {
  selectedMember?: string;
  walkInName?: string;
  mobileNum?: string;
  appointmentDate?: string;
  appointmentTime?: string;
  appointmentEndDate?: string;
  appointmentEndTime?: string;
  staffId?: string;
  outletId?: string;
  [key: `service_${number}`]: string; // Dynamic keys for services
  remarks?: string;
};

const Appointment: React.FC = () => {
  const events = [
    { title: "2:00 PM Ashley", date: "2024-10-09" },
    { title: "1:30 PM Elise", date: "2024-10-10" },
    { title: "2:00 PM John", date: "2024-10-10" },
    { title: "1:30 PM Elise", date: "2024-10-02" },
    { title: "1:30 PM Elise", date: "2024-12-05" },
    { title: "3:30 PM Elise", date: "2024-10-08" },
    { title: "4:30 PM John", date: "2024-11-08" },
  ];

  const appointmentsData = [
    {
      timeRange: "11:00am - 11:30am",
      appointments: [
        { staff: "Jenny", service: "Facial", customerName: "Abc" },
        { staff: "Catherine", service: "Eye Brow", customerName: "raj" },
      ],
    },
    {
      timeRange: "1:00pm - 1:30pm",
      appointments: [
        { staff: "Jenny", service: "Eye Brow", customerName: "RM" },
      ],
    },
  ];

  //form-controls

  const [memberData, setMemberData] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [appointMember, setAppointMember] = useState(false);
  const [walkInName, setwalkInName] = useState("");
  const [mobileNum, setmobileNum] = useState("");
  const [appointmentDate, setAppointmentDate] = useState<any>(null);
  const [appointmentEndDate, setAppointmentEndDate] = useState<any>(null);
  const [showAppointmentEndTime, setShowAppointmentEndTime] =
    useState<boolean>(false);
  const [appointmentTime, setAppointmentTime] = useState<any>(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState<any>(null);
  const [staffData, setStaffData] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [outletId, setOutletId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [serviceData, setServiceData] = useState<any[]>([]);
  const [services, setServices] = useState([{ serviceid: "", name: "" }]);
  const [serviceErrors, setServiceErrors] = useState<ServiceErrors>({});
  const [date, setDate] = useState<any>(moment(new Date()));
  const [showAppointPopup, setShowAppointmentPopup] = useState<boolean>(false);
  const [showStaffOffDayPopup, setShowStaffOffDayPopup] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [staffOffDate, setStaffOffDate] = useState<any>(null);
  const [selectedStaffIds, setSelectedStaffIds] = useState<any>([]);
  const [totalAppointments, setTotalAppointments] = useState("");
  const [dataForTable, setDataForTable] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({});

  const exampleAppointment = {
    walkInName: "ABC",
    phoneNumber: "60127733241",
    appointmentTime: "2023-12-29 @ 11:00 AM",
    staff: "Catherine",
    branch: "Ebteq Salon",
    service: "Eye Brow (Promotion 10%)",
    remark: "-",
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function resetAppointment() {
    setSelectedMember("");
    setStaffId("");
    setServices([{ serviceid: "", name: "" }]);
    setwalkInName("");
    setOutletId("");
    setmobileNum("");
    setRemarks("");
    setAppointmentDate(null);
    setAppointmentTime(null);
    setAppointmentEndDate(null);
    setAppointmentEndTime(null);
    setAppointMember(false);
    setShowAppointmentEndTime(false);
  }

  const handleCheckboxChange = (id: any) => {
    setSelectedStaffIds(
      (prevSelected: any) =>
        prevSelected.includes(id)
          ? prevSelected.filter((staffId: any) => staffId !== id) // Remove if unchecked
          : [...prevSelected, id] // Add if checked
    );
  };

  const [currentView, setCurrentView] = useState<string>("timeGridWeek");

  const handleDateClick = (arg: any) => {
    const title = window.prompt("New Event name");
    if (title) {
      const calendarApi = arg.view.calendar;
      calendarApi.addEvent({
        title,
        start: arg.date,
        allDay: true,
      });
    }
  };

  const onDeleteCancel = () => {
    if (showStaffOffDayPopup) {
      setShowStaffOffDayPopup(false);
    } else {
      setShowAppointmentPopup(false);
    }
  };

  const handleViewChange = (view: string) => {
    setCurrentView(view);
  };

  const handleAddService = () => {
    setServices([...services, { serviceid: "", name: "" }]);
  };

  const handleRemoveService = (index: any) => {
    if (index === 0 && services.length === 1) {
      return;
    }
    setServices((prevGroups: any) =>
      prevGroups.filter((_: any, i: any) => i !== index)
    );
  };

  const getAvailableServices = (selectedServiceId: any) => {
    return serviceData.filter(
      (service) => !selectedServiceId.includes(service.id)
    );
  };

  const handleServiceChange = (index: any, field: any, value: any) => {
    setServices((prevServices: any) => {
      const updatedServices = [...prevServices];
      updatedServices[index] = {
        ...updatedServices[index],
        [field]: value,
      };
      return updatedServices;
    });
  };

  const handleOnAppointmentClickTable = (data: any) => {
    setIsOpen(true);
    console.log(data, "dattaa");
    setAppointmentDetails(data);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleEditAppointment = () => {};

  const handleDeleteAppointment = () => {};

  const handleFieldChange = (fieldName: any, value: any) => {
    // Update the field's state value
    switch (fieldName) {
      case "selectedMember":
        setSelectedMember(value);
        break;
      case "walkInName":
        setwalkInName(value);
        break;
      case "mobileNum":
        setmobileNum(value);
        break;
      case "staffId":
        setStaffId(value);
        break;
      case "outletId":
        setOutletId(value);
        break;
      case "appointmentDate":
        setAppointmentDate(value);
        break;
      case "appointmentTime":
        setAppointmentTime(value);
        break;
      case "appointmentEndDate":
        setAppointmentEndDate(value);
        break;
      case "appointmentEndTime":
        setAppointmentEndTime(value);
        break;
      default:
        break;
    }

    // Clear error for the specific field
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  };

  //Validations
  const validateAppointmentForm = () => {
    const errors: any = {};

    if (appointMember && !selectedMember) {
      errors.selectedMember = "Member selection is required for appointments.";
    } else if (!appointMember && !walkInName) {
      errors.walkInName = "Walk-in name is required.";
    }

    if (!mobileNum) {
      errors.mobileNum = "Phone number is required.";
    } else if (!/^\d+$/.test(mobileNum)) {
      errors.mobileNum = "Phone number must contain only digits.";
    } else if (mobileNum.length > 12) {
      errors.mobileNum = "Phone number cannot exceed 12 digits.";
    }

    if (!appointmentDate) {
      errors.appointmentDate = "Appointment date is required.";
    }

    if (!appointmentTime) {
      errors.appointmentTime = "Appointment time is required.";
    }

    if (showAppointmentEndTime) {
      if (!appointmentEndDate) {
        errors.appointmentEndDate = "End date is required.";
      }
      if (!appointmentEndTime) {
        errors.appointmentEndTime = "End time is required.";
      }
    }

    if (!staffId) {
      errors.staffId = "Staff selection is required.";
    }

    if (!outletId) {
      errors.outletId = "Outlet selection is required.";
    }

    services.forEach((service, index) => {
      if (!service.serviceid) {
        errors[`service_${index}`] = "Service selection is required.";
      }
    });

    if (remarks && remarks.length > 200) {
      errors.remarks = "Remarks cannot exceed 200 characters.";
    }

    return errors;
  };

  //Check validations and handle api call
  const handleSave = () => {
    const errors = validateAppointmentForm();

    if (Object.keys(errors).length === 0) {
      let body = {
        memberid: selectedMember,
        staffid: staffId,
        serviceid: services,
        customername: walkInName,
        outlet_id: outletId,
        phone: mobileNum,
        remarks: remarks,
        appointmentdate: moment(appointmentDate).format("DD-MM-YYYY"),
        appointmenttime: moment(appointmentTime, "HH:mm").format("hh:mm A"),
        appointmentendtime: moment(appointmentEndTime).format("hh:mm A"),
        appointmentenddate: moment(appointmentEndDate).format("DD-MM-YYYY"),
        isMember: appointMember,
        isAppointmentEndTime: showAppointmentEndTime,
      };

      handleAddAppointment(body);
    } else {
      setErrors(errors); // Display errors
    }
  };

  //Api call for add appointment
  const handleAddAppointment = async (body: any) => {
    try {
      let url = "/appointment";
      const response = await post(url, body);

      if (response && response.data) {
        if (response.data.success) {
          getAppointmentDataForTable();
          resetAppointment();
          setShowAppointmentPopup(false);
        } else {
          console.log("Error in Api call: ", response.data);
        }
      } else {
        console.log("Response not found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //api call for outlet dropdown
  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;

        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  // Api call for member dropdown
  async function getMembers() {
    try {
      let url = "/members/dropdown";
      const response = await get(url);

      if (response && response.data) {
        setMemberData(response.data);
      } else {
        console.log("Response not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Api call for staff dropdown
  async function getStaffs() {
    try {
      let url = "/staff/dropdown";
      const response = await get(url);

      if (response && response.data) {
        if (response.data) {
          setStaffData(response.data.data);
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Api call for service dropdown
  async function getServiceData() {
    try {
      const url = "/service/dropdown";
      const response = await get(url);
      if (response?.data?.success) {
        setServiceData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  //API call for Add Staff's off day
  async function AddStaffOfDay() {
    let url = "/managestaff/";
    let body = {
      staffid: selectedStaffIds,
      offdate: moment(staffOffDate).format("YYYY-MM-DD"),
    };

    const response = await post(url, body);

    if (response && response.data) {
      if (response.data.success) {
        setShowStaffOffDayPopup(false);
      } else {
        console.log("Error in api call: ", response.data.message);
      }
    } else {
      console.log("Response not found");
    }
  }

  //get Appointment data for table view
  async function getAppointmentDataForTable() {
    try {
      const url = "/appointment/date/table/14-10-2024";
      let body = {
        startTime: "10:00 AM",
        endTime: "10:30 PM",
        interval: 30,
      };
      const response = await post(url, body);

      if (response && response.data) {
        if (response.data.success) {
          let count = response.data.data[0].appointments.totalRecords;
          setDataForTable(response.data.data[0]);
          setTotalAppointments(count);
          console.log(response.data.data[0], "response");
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not Found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAppointmentDataForTable();
    getStaffs();
    getMembers();
    getOutlets();
    getServiceData();
  }, []);

  return (
    <Stack spacing={3}>
      <Stack spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: isMobile ? "1.8rem" : "2.2rem" }}
          >
            Appoinment
          </Typography>
        </Stack>

        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            sm={6}
            style={{ marginBottom: isMobile ? "10px" : "0px" }}
          >
            <DatePicker
              sx={{ width: "100%" }}
              value={date}
              onChange={(date) => setDate(date)}
              format="DD/MM/YYYY"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            spacing={isMobile ? 1 : 3}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                size={isMobile ? "small" : "medium"}
                onClick={() => setShowStaffOffDayPopup(true)}
              >
                Manage staff off-day
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size={isMobile ? "small" : "medium"}
                onClick={() => {
                  setShowAppointmentPopup(true);
                  resetAppointment();
                }}
              >
                Add new appointment
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={isMobile ? 2 : 4} my={isMobile ? 2 : 4}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            alignItems="center"
            className="bg-white border-round-xs"
          >
            <Typography variant="subtitle1">
              Total appointment for today
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              {totalAppointments}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            alignItems="center"
          >
            <Typography variant="subtitle1">Show in:</Typography>
            <Stack direction="row" spacing={1}>
              {["dayGridMonth", "timeGridWeek", "timeGridDay"].map(
                (view, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    size={isMobile ? "small" : "medium"}
                    className={`calendar-btn ${
                      currentView === view ? "selected" : ""
                    }`}
                    onClick={() => handleViewChange(view)}
                  >
                    {view === "dayGridMonth"
                      ? "Month"
                      : view === "timeGridWeek"
                      ? "Day (Table)"
                      : "Day (List)"}
                  </Button>
                )
              )}
            </Stack>
          </Grid>
        </Grid>

        <CustomClendar
          staffMembers={staffData}
          timeSlots={dataForTable?.timeSlots}
          appointments={dataForTable.appointments?.appointments}
          type={currentView}
          appointmentsDataForList={appointmentsData}
          eventsForMonths={events}
          handleDateClickInMonths={(e: any) => handleDateClick(e)}
          offDayStaffList={dataForTable.offDayStaffList}
          viewAppointment={() => setIsOpen(true)}
          onAppointmentClick={(appointment: any) =>
            handleOnAppointmentClickTable(appointment)
          }
        />

        <AppointmentDetailsModal
          open={isOpen}
          onClose={handleClose}
          onEdit={handleEditAppointment}
          onDelete={handleDeleteAppointment}
          appointment={appointmentDetails}
        />

        <Dialog
          open={showAppointPopup}
          PaperProps={{
            sx: {
              width: isMobile ? "100vw" : "40vw",
              maxWidth: "none",
            },
          }}
          onClose={() => {
            if (!showAppointPopup) return;
            setShowAppointmentPopup(false);
          }}
        >
          <DialogTitle>Add new appointment</DialogTitle>
          <DialogContent>
            <form>
              <div className="grid m-0">
                <div className="col-12 flex align-items-center">
                  <Checkbox
                    id="appointmember"
                    checked={appointMember}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAppointMember(true);
                      } else {
                        setAppointMember(false);
                      }
                    }}
                  />
                  <label htmlFor="appointmember" className="ml-2 form-label">
                    Appointment for member
                  </label>
                </div>
                {appointMember ? (
                  <div className="col-12">
                    <TextField
                      select
                      label="Member"
                      value={selectedMember}
                      onChange={(e) => {
                        handleFieldChange("selectedMember", e.target.value);
                      }}
                      fullWidth
                      error={!!errors.selectedMember}
                      helperText={errors.selectedMember}
                    >
                      {memberData.map((category: any) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                ) : (
                  <div className="col-12">
                    <TextField
                      label="Walk in name"
                      value={walkInName}
                      onChange={(e) =>
                        handleFieldChange("walkInName", e.target.value)
                      }
                      fullWidth
                      style={{
                        width: "100%",
                      }}
                      error={!!errors.walkInName}
                      helperText={errors.walkInName}
                    />
                  </div>
                )}
                <div className="col-12">
                  <TextField
                    label="Phone number"
                    value={mobileNum}
                    type="number"
                    onChange={(e) =>
                      handleFieldChange("mobileNum", e.target.value)
                    }
                    fullWidth
                    style={{
                      width: "100%",
                    }}
                    error={!!errors.mobileNum}
                    helperText={errors.mobileNum}
                  />
                </div>
                <div className="col-12">
                  <div className="grid">
                    <div className="col-4">
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="Date"
                        format="DD/MM/YYYY"
                        value={appointmentDate}
                        onChange={(date: any) =>
                          handleFieldChange("appointmentDate", date)
                        }
                        minDate={dayjs()}
                      />
                      {errors.appointmentDate && (
                        <Typography style={{ color: "red", fontSize: "11px" }}>
                          Date is required.
                        </Typography>
                      )}
                    </div>
                    <div className="col-8">
                      <TimePicker
                        sx={{ width: "100%" }}
                        label="Appointment Time"
                        value={appointmentTime}
                        onChange={(newValue) =>
                          handleFieldChange("appointmentTime", newValue)
                        }
                      />
                      {errors.appointmentTime && (
                        <Typography style={{ color: "red", fontSize: "12px" }}>
                          Appointment time is required.
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 flex align-items-center">
                  <Checkbox
                    id="showappointendtime"
                    checked={showAppointmentEndTime}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShowAppointmentEndTime(true);
                      } else {
                        setShowAppointmentEndTime(false);
                      }
                    }}
                  />
                  <label
                    htmlFor="showappointendtime"
                    className="ml-2 form-label"
                  >
                    Appointment end time
                  </label>
                </div>
                {showAppointmentEndTime && (
                  <div className="col-12">
                    <div className="grid">
                      <div className="col-4">
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="End Date"
                          format="DD/MM/YYYY"
                          value={appointmentEndDate}
                          onChange={(date: any) =>
                            handleFieldChange("appointmentEndDate", date)
                          }
                          // minDate={appointmentDate}
                        />
                        {errors.appointmentEndDate && (
                          <Typography
                            style={{ color: "red", fontSize: "11px" }}
                          >
                            End Date is required.
                          </Typography>
                        )}
                      </div>
                      <div className="col-8">
                        <TimePicker
                          sx={{ width: "100%" }}
                          label="Appointment End Time"
                          value={appointmentEndTime}
                          onChange={
                            (newValue: any) =>
                              handleFieldChange("appointmentEndTime", newValue)
                            // setAppointmentEndTime(newValue)
                          }
                        />
                        {errors.appointmentEndTime && (
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            Appointment End time is required.
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <TextField
                    label="Staff"
                    select
                    id="staff"
                    value={staffId}
                    onChange={(e) =>
                      handleFieldChange("staffId", e.target.value)
                    }
                    className="w-full"
                    fullWidth
                    InputProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                    error={!!errors.staffId}
                    helperText={errors.staffId}
                  >
                    {staffData.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col-12">
                  <TextField
                    label="Outlet"
                    select
                    id="outlet"
                    value={outletId}
                    onChange={(e) =>
                      handleFieldChange("outletId", e.target.value)
                    }
                    className="w-full"
                    fullWidth
                    error={!!errors.outletId}
                    helperText={errors.outletId}
                    InputProps={{
                      style: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    {outlets.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.MerchantName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="col-12">
                  {services.map((service, index) => {
                    const selectedServiceIds = services
                      .filter((_, idx) => idx !== index)
                      .map((service) => service.serviceid);

                    return (
                      <div className="" key={index}>
                        <FormControl
                          fullWidth
                          error={!!errors[`service_${index}`]}
                          // helperText={errors[`service_${index}`]}
                        >
                          <InputLabel>Service</InputLabel>
                          <Select
                            value={service.serviceid}
                            onChange={(e) => {
                              handleServiceChange(
                                index,
                                "serviceid",
                                e.target.value
                              );
                              const selectedService = serviceData.find(
                                (service) => service.id === e.target.value
                              );
                              handleServiceChange(
                                index,
                                "name",
                                selectedService?.name || ""
                              );
                              if (e.target.value !== "0") {
                                setServiceErrors("");
                              }
                              handleFieldChange(
                                `service_${index}`,
                                e.target.value
                              );
                            }}
                            input={<OutlinedInput label="Service" />}
                          >
                            {getAvailableServices(selectedServiceIds).map(
                              (service) => (
                                <MenuItem key={service.id} value={service.id}>
                                  {service.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {serviceErrors[index] && (
                            <FormHelperText error>
                              {serviceErrors[index]}
                            </FormHelperText>
                          )}
                        </FormControl>

                        {services && services.length > 1 && (
                          <span
                            style={{
                              cursor: "pointer",
                              color: "red",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            onClick={() => handleRemoveService(index)}
                          >
                            X
                          </span>
                        )}
                      </div>
                    );
                  })}
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddService}
                  >
                    Add Service
                  </Button>
                </div>
                <div className="col-12">
                  <TextField
                    id="remarks"
                    label="Remarks"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    fullWidth
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      marginTop: "10px",
                    }}
                    multiline
                    rows={2}
                  />
                </div>
                <div className="col-12 flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    className="ml-2"
                    onClick={onDeleteCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog
          open={showStaffOffDayPopup}
          onClose={() => {
            if (!showStaffOffDayPopup) return;
            setShowStaffOffDayPopup(false);
          }}
        >
          <DialogTitle>Manage staff off-day</DialogTitle>
          <DialogContent>
            <form>
              <div className="grid m-0">
                <div className="col-12">
                  <DatePicker
                    sx={{ width: "90%" }}
                    value={staffOffDate}
                    onChange={(date: any) => setStaffOffDate(date)}
                    format="DD/MM/YYYY"
                    minDate={dayjs()}
                  />
                </div>
                <div className="col-12 flex align-items-center">
                  <div className="flex flex-wrap justify-content-start gap-3">
                    {staffData.map((staff: any) => (
                      <div key={staff.id} className="flex align-items-center">
                        <Checkbox
                          id={`staff-${staff.id}`}
                          value={staff.id}
                          checked={selectedStaffIds.includes(staff.id)}
                          onChange={() => handleCheckboxChange(staff.id)}
                        />
                        <label htmlFor={`staff-${staff.id}`} className="ml-2">
                          {staff.firstName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-12 flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!staffOffDate || selectedStaffIds.length === 0}
                    onClick={() => AddStaffOfDay()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    className="ml-2"
                    onClick={onDeleteCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default Appointment;
