import { MenuViewPanel, Roles } from "./types/enum";

export const reportPaths = [
  {
    title: "Sales Report",
    path: "/invoices",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Customer Report",
    path: "/customerReport",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Inventory Report",
    path: "/inventoryReport",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Inventory Stock Report",
    path: "/inventoryInOutReport",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Payment Report",
    path: "/paymentReport",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
];
