import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { CONSTANTS } from "../../constants";
import axiosService from "../../services/axios";
import { Merchant } from "../../types/merchant-interface";
import { Staff } from "../../types/staff-interface";
import { useAlert } from "../../components/CommonAlert";
import { useParams } from "react-router-dom";
import { RecordActions, RecordTypes } from "../../types/enum";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";
import ImageUpload from "../../components/ImageUpload";
import { useState } from "react";
import { get, post, put } from "src/services/api.service";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
export function StaffForm(): React.JSX.Element {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("General");
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const { action, id } = useParams();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { showAlert } = useAlert();
  const methods = useForm<any>({
    defaultValues: {
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      address2: "",
      postcode: "",
      state: "",
      country: "",
      picture: "",
      gender: "",
      preferLanguage: "",
      outlet_id: "",
      username: "",
      password: "",
    },
  });

  const createNewStaff = async (data: any) => {
    try {
      const response = await post(`/staff`, data);
      if (response.status != 200) {
        setLoading(false);
        showAlert(response.data.message || "Failed to Save Staff", "error");
      } else {
        const json = response.data.data;
        setLoading(false);
        showAlert("Staff Created Successfully", "success");
      }
    } catch (ex: any) {
      setLoading(false);
      showAlert(ex.response.data.message || "Failed to Save Staff", "error");
      console.error("Exception Caught", ex);
    }
  };

  const editStaff = async (data: any) => {
    try {
      const response = await put(`/staff/${id}`, data);
      if (response.status != 200) {
        setLoading(false);
        showAlert("Failed to Update Staff", "error");
      } else {
        const json = response.data.data;
        setLoading(false);
        showAlert("Staff Updated Successfully", "success");
      }
    } catch (ex) {
      showAlert("Failed to Update Staff", "error");
      setLoading(false);
      console.error("Exception Caught", ex);
    }
  };

  const getStaffDetails = async () => {
    try {
      const response = await get(`/staff/${id}`);
      if (response.status != 200) {
        showAlert("Failed to Retrive Staff Details", "error");
      } else {
        const json: Staff = response.data.data;
        methods.reset(json);
      }
    } catch (ex) {
      showAlert("Failed to Retrive Staff Details", "error");
      console.error("Exception Caught", ex);
    }
  };
  const onSubmit = methods.handleSubmit(async (data: any) => {
    try {
      setTimeout(() => {
        if (loading) {
          return;
        }
        setLoading(true);

        if (action == RecordActions.add) {
          createNewStaff(data);
        } else if (action == RecordActions.edit) {
          editStaff(data);
        }
      });
    } catch (ex) {
      setLoading(false);
    } finally {
    }
  });
  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([...json]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };
  React.useEffect(() => {
    getOutlets();
    if (action == RecordActions.edit || action == RecordActions.view) {
      getStaffDetails();
    }
  }, []);
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Staffs</Typography>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            {/* <Button color="inherit" startIcon={<UploadIcon fontSize="var(--icon-fontSize-md)" />}>
          Quick Actions
        </Button> */}
          </Stack>
        </Stack>
        <div>
          <Button
            startIcon={<ListIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => navigate("/staff")}
          >
            View All
          </Button>
        </div>
      </Stack>

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="outlet_id"
                    rules={{
                      required: "Select the Outlet.",
                    }}
                    control={methods.control}
                    defaultValue={[]}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id="outlet-select-label">Outlet</InputLabel>
                        <Select
                          {...field}
                          label="Outlet"
                          multiple
                          value={Array.isArray(field.value) ? field.value : []}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {(Array.isArray(selected) ? selected : []).map(
                                (value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      outlets.find(
                                        (outlet) => outlet.id === value
                                      )?.MerchantName || "Unknown"
                                    }
                                  />
                                )
                              )}
                            </Box>
                          )}
                          onChange={(event) => {
                            const { value } = event.target;
                            field.onChange(Array.isArray(value) ? value : []);
                          }}
                        >
                          {outlets.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.MerchantName}
                            </MenuItem>
                          ))}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText style={{ color: "red" }}>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="phone"
                    rules={{
                      required: "Phone is required",
                      minLength: {
                        value: 10,
                        message: "Phone number should be 10 digits.",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number should be 10 digits.",
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Phone number must contain only digits.",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.phone?.message ===
                          "string"
                            ? methods.formState.errors?.phone?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        id="phone"
                        label="Phone"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="firstName"
                    rules={{
                      required: "First name is required",
                      maxLength: {
                        value: 45,
                        message:
                          "First Name should be less than 45 characters.",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action === RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.firstName
                            ?.message === "string"
                            ? methods.formState.errors?.firstName?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        id="firstName"
                        label="First Name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="lastName"
                    rules={{
                      required: "Last name is required",
                      maxLength: {
                        value: 45,
                        message: "Last Name should be less than 45 characters.",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.lastName?.message ===
                          "string"
                            ? methods.formState.errors?.lastName?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Last Name"
                        id="lastName"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="email"
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Ex. abc@gmail.com",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.email?.message ===
                          "string"
                            ? methods.formState.errors?.email?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Email"
                        id="email"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="gender"
                    rules={{
                      required: "Select the Gender.",
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                          {...field}
                          labelId="gender-label"
                          label="Gender"
                          variant="outlined"
                          error={!!fieldState.error}
                          defaultValue=""
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {genderOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {fieldState.error && (
                          <FormHelperText style={{ color: "red" }}>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="address"
                    rules={{
                      required: "Address is required",
                      maxLength: {
                        value: 200,
                        message: "Address should be less than 200 characters.",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.address?.message ===
                          "string"
                            ? methods.formState.errors?.address?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Address"
                        id="Address"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="address2"
                    rules={{
                      maxLength: {
                        value: 200,
                        message: "Address2 should be less than 200 characters.",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.address2?.message ===
                          "string"
                            ? methods.formState.errors?.address2?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Address Line 2"
                        id="address2"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="postcode"
                    rules={{
                      maxLength: {
                        value: 6,
                        message: "Code should be 6 digits.",
                      },
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Post Code must contain only digits.",
                      },
                    }}
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.postcode?.message ===
                          "string"
                            ? methods.formState.errors?.postcode?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Postcode"
                        id="postcode"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="state"
                    // rules={{
                    //   required: "State is required",
                    // }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        // helperText={
                        //   typeof methods.formState.errors?.state?.message ===
                        //   "string"
                        //     ? methods.formState.errors?.state?.message
                        //     : ""
                        // }
                        // FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="State"
                        id="state"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="country"
                    // rules={{
                    //   required: "Country is required",
                    // }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        // helperText={
                        //   typeof methods.formState.errors?.country?.message ===
                        //   "string"
                        //     ? methods.formState.errors?.country?.message
                        //     : ""
                        // }
                        // FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        label="Country"
                        id="country"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="preferLanguage"
                    // rules={{
                    //   required: "Language is required",
                    // }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        // helperText={
                        //   typeof methods.formState.errors?.preferLanguage
                        //     ?.message === "string"
                        //     ? methods.formState.errors?.preferLanguage?.message
                        //     : ""
                        // }
                        // FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        id="preferLanguage"
                        label="preferLanguage"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name="picture"
                    // rules={{
                    //   required: "Picture is required",
                    // }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <>
                        <ImageUpload
                          onImageChange={(url: string | null) => {
                            field.onChange(url);
                          }}
                          imageUrl={field.value}
                          mode={action}
                          name={"picture"}
                          label="Picture"
                        />
                        {/* {fieldState.error && (
                          <FormHelperText style={{ color: "red" }}>
                            {fieldState.error.message}
                          </FormHelperText>
                        )} */}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="username"
                    rules={{
                      required: "User Name is required",
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.username?.message ===
                          "string"
                            ? methods.formState.errors?.username?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        id="username"
                        label="Username"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="password"
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      maxLength: {
                        value: 15,
                        message:
                          "Password must be no more than 15 characters long",
                      },
                      pattern: {
                        value:
                          /^(?=.*\d)(?=.*[@$!%*^()?#&.'"/])[A-Za-z\d@$!#%*^?()&'"/]{8,15}$/,
                        message:
                          "Password must contain at least one number and one special character",
                      },
                    }}
                    control={methods.control}
                    render={({ field, fieldState }) => (
                      <TextField
                        InputProps={{
                          readOnly: action == RecordActions.view,
                        }}
                        helperText={
                          typeof methods.formState.errors?.password?.message ===
                          "string"
                            ? methods.formState.errors?.password?.message
                            : ""
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                        {...field}
                        fullWidth
                        id="password"
                        label="Set New Password"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <br />
          <br />
          <br />
          <br />
          {action != RecordActions.view && (
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                zIndex: 9,
                width: "100%",
                backgroundColor: "#f5f5f5",
                padding: 2,
                display: "flex",
                justifyContent: "flex-end",
                boxShadow: "0px -1px 5px rgba(0,0,0,0.1)",
              }}
            >
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </FormProvider>
    </Stack>
  );
}
