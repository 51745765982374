import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  TablePagination,
  Stack,
  Typography,
  Card,
  Box,
  OutlinedInput,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search,
} from "@mui/icons-material";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { deleteData, get, post, put } from "../../services/api.service";
import { useAuth } from "src/context/AuthContext";
import { Merchant } from "src/types/merchant-interface";
import axiosService from "src/services/axios";
import { CONSTANTS } from "../../constants";
import { RecordTypes } from "src/types/enum";
import CustomDataTable from "src/components/CustomDataTable";

const ServicesPage: React.FC = () => {
  const { user } = useAuth();
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [AddDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [EditDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [AddCategoryDialogVisible, setAddCategoryDialogVisible] =
    useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [redeemPoints, setRedeemPoints] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [serviceNameError, setServiceNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [redeemPointsError, setRedeemPointsError] = useState("");
  const [isCourse, setIsCourse] = useState(false);

  const columns = [
    { label: "#", field: "index" },
    { label: "Name", field: "name" },
    { label: "Price", field: "price" },
    { label: "Category", field: "categoryname" },
    { label: "Redeem Points", field: "redeem_points" },
    { label: "action", field: "" },
  ];

  const openAddNewService = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmEdit = (rowData: any) => {
    setSelectedId(rowData.id);
    setEditDialogVisible(true);
    getDataByServiceID(rowData.id);
    setIsEdit(true);
  };

  const confirmDelete = (rowData: any) => {
    setSelectedId(rowData.id);
    setDeleteDialogVisible(true);
  };

  const onDeleteCancel = () => {
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setDeleteDialogVisible(false);
    setIsEdit(false);
  };

  const categorybox = [
    {
      name: "This Product is redeemable by reward points",
      key: "Reward Points",
    },
  ];

  const [selectedCategoryBox, setSelectedCategoryBox] = useState([
    categorybox[0],
  ]);

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let _selectedCategoryBox = [...selectedCategoryBox];
    if (e.target.checked) {
      _selectedCategoryBox.push(categorybox[0]);
    } else {
      _selectedCategoryBox = _selectedCategoryBox.filter(
        (category) => category.key !== categorybox[0].key
      );
    }
    setSelectedCategoryBox(_selectedCategoryBox);
    setRedeemPoints("");
    setRedeemPointsError("");
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          style={{
            color: "#264164",
          }}
        >
          <VisibilityIcon style={{ height: "21px", width: "21px" }} />
        </IconButton>

        <IconButton onClick={() => confirmEdit(rowData)}>
          <EditIcon color="primary" style={{ height: "21px", width: "21px" }} />
        </IconButton>
        <IconButton onClick={() => confirmDelete(rowData)}>
          <DeleteIcon color="error" style={{ height: "21px", width: "21px" }} />
        </IconButton>
      </div>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isRewardPointsSelected = selectedCategoryBox.some(
    (category) => category.key === "Reward Points"
  );

  const onDeleteCategory = () => {
    setAddCategoryDialogVisible(false);
  };

  const resetFields = () => {
    setServiceName("");
    setSelectedBranchId("");
    setSelectedCategoryId("");
    setRedeemPoints("");
    setSelectedCategory("");
    setSelectedBranch("");
    setPrice("");
    setServiceNameError("");
    setCategoryError("");
    setPriceError("");
    setRedeemPointsError("");
    setIsCourse(false);
  };

  async function getDataByServiceID(id: any) {
    try {
      let url = `/service/${id}`;
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          let service = response.data.data;
          setServiceName(service.name);
          setSelectedBranch(service.outlet_id);
          setSelectedBranchId(service.outlet_id);
          setSelectedCategory(service.category_id);
          setSelectedCategoryId(service.category_id);
          setPrice(service.price.toString());
          setIsCourse(service.iscourse);
          setRedeemPoints(service.redeem_points.toString());
          if (service.redeem_points) {
            setSelectedCategoryBox([categorybox[0]]);
          }
          setSelectedBranch(response.data.data.outlet_id);
          setSelectedCategory(response.data.data.category_id);
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function getAllService() {
    try {
      let url = "/service/getall";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setServiceData(response.data.data);
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addCategory() {
    try {
      let url = "/subcategory/Service";
      let body = {
        name: categoryName,
        isActive: true,
      };
      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddCategoryDialogVisible(false);
          getAllCategory();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllCategory() {
    try {
      let url = "/category/type/Service";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setCategoryData(response.data.data);
          console.log(response.data.data, "response");
        } else {
          console.log("Error in API call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteService() {
    try {
      let url = `/service/${selectedId}`;
      const response = await deleteData(url);
      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log("Error deleting product: ", error);
    }
  }

  async function addService() {
    try {
      let url = "/service";
      let body = {
        name: serviceName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        category_id: selectedCategory,
        redeem_points: redeemPoints,
        // outlet_id: selectedBranch,
        outlet_id: "",
        Serviceskubarcode: "SKUBARCODE123",
        servicebrand: "Brand A",
        servicegroup: "Group A",
        servicecolorgroup: "Color Group A",
        servicegroupsize: "Size Group A",
        servicetax: "5.00",
        servicevarientgroup: "Variant Group A",
        servicetextragroup: "Extra Group A",
        serviceuplinerewardgroup: "Reward Group A",
        servicesize: "Medium",
        serviceweight: "500g",
        servicetype: "Type A",
        servicenormal: true,
        serviceofferprice: "45.00",
        servicecosting: "40.00",
        isreward: true,
        isCourse: isCourse,
      };

      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateService() {
    try {
      let url = `/service/${selectedId}`;
      let body = {
        name: serviceName,
        description: "High-quality shampoo for all hair types.",
        price: parseInt(price),
        stock: 100,
        redeem_points: redeemPoints,
        // outlet_id: selectedBranch,
        outlet_id: "",
        category_id: selectedCategory,
        isCourse: isCourse,
      };
      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllService();
        } else {
          console.log("Error in API call ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = () => {
    if (validateFields()) {
      if (!isEdit) {
        addService();
      } else {
        updateService();
      }
    }
  };

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  useEffect(() => {
    getAllService();
    getAllCategory();
    // getBranchList();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        serviceData.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(serviceData);
    }
  }, [searchQuery, serviceData]);

  const validateFields = () => {
    let valid = true;

    if (!serviceName.trim()) {
      setServiceNameError("Service Name is required");
      valid = false;
    } else {
      setServiceNameError("");
    }

    // Category validation
    if (!selectedCategory && !selectedCategoryId) {
      setCategoryError("Category is required");
      valid = false;
    } else {
      setCategoryError("");
    }

    // Price validation
    // if (!price || price === "0") {
    //   setPriceError("Price must be a positive number");
    //   valid = false;
    // } else {
    //   setPriceError("");
    // }
    if (!price || price === "0") {
      setPriceError("Price must be a positive number");
      valid = false;
    } else if (!price || Number(price) <= 0) {
      setPriceError("Price must be greater than 0");
      valid = false;
    } else {
      setPriceError("");
    }

    // Redeem Points
    // if (!redeemPoints || redeemPoints === "0") {
    //   setRedeemPointsError("Redeem Points must be a positive number");
    //   valid = false;
    // } else {
    //   setRedeemPointsError("");
    // }
    if (isRewardPointsSelected) {
      if (!redeemPoints || redeemPoints === "0") {
        setRedeemPointsError("Redeem Points must be a positive number");
        valid = false;
      } else if (isRewardPointsSelected && Number(redeemPoints) <= 0) {
        setRedeemPointsError("Redeem Points must be a positive number");
        valid = false;
      } else {
        setRedeemPointsError("");
      }
    }

    return valid;
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Services</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Service"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => openAddNewService()}
        isEditIcon={true}
        isDeleteIcon={true}
        onPressEdit={(rowData: any) => {
          confirmEdit(rowData);
        }}
        onPressDelete={(rowData: any) => {
          confirmDelete(rowData);
        }}
      />

      {/* Add and Edit Dialog */}
      <Dialog
        open={!isEdit ? AddDialogVisible : EditDialogVisible}
        onClose={onDeleteCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add Service" : "Update Existing Service"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Service Name"
            value={serviceName}
            onChange={(e) => {
              setServiceName(e.target.value);
              if (e.target.value) {
                setServiceNameError("");
              }
            }}
            error={!!serviceNameError}
            helperText={serviceNameError}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              if (e.target.value !== "0") {
                setPriceError("");
              }
            }}
            type="number"
            error={!!priceError}
            helperText={priceError}
          />

          <TextField
            select
            label="Category"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value as string);
              if (e.target.value) {
                setCategoryError("");
              }
            }}
            error={!!categoryError}
            helperText={categoryError}
            fullWidth
            margin="normal"
            InputProps={{
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {categoryData.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>

          <div style={{ flexDirection: "column", display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isCourse} // Checked if isCourse is false (Single is selected)
                  onChange={(e) => {
                    setIsCourse(false); // Set to false when Single is checked
                  }}
                />
              }
              label={"Single"}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isCourse} // Checked if isCourse is true
                  onChange={(e) => {
                    setIsCourse(true); // Set to true when Course is checked
                  }}
                />
              }
              label={"Course"}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isRewardPointsSelected}
                  onChange={onCategoryChange}
                />
              }
              label={categorybox[0].name}
            />
          </div>
          {isRewardPointsSelected && (
            <TextField
              fullWidth
              margin="normal"
              label="Redeem Points"
              value={redeemPoints}
              type="number"
              error={!!redeemPointsError}
              helperText={redeemPointsError}
              onChange={(e) => {
                setRedeemPoints(e.target.value);
                if (e.target.value !== "0") {
                  setRedeemPointsError("");
                }
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new category */}
      <Dialog
        open={AddCategoryDialogVisible}
        onClose={onDeleteCategory}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: { padding: "10px" },
        }}
      >
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: "10px" }}>
            <TextField
              label="Category Name"
              placeholder="Add Category Name"
              fullWidth
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              variant="outlined"
              style={{ marginTop: "10px" }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={addCategory} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCategory} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteService}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete service"}
        title={"Confirm Delete"}
      />
    </Stack>
  );
};
export default ServicesPage;
