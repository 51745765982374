import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { get } from "../../services/api.service";
import { useAuth } from "src/context/AuthContext";
import CustomDataTable from "src/components/CustomDataTable";

function PaymentReport() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [assignstock, setAssignstock] = React.useState<any[]>([]);

  const columns = [
    { label: "#", field: "index" },
    { label: "Date", field: "created_at" },
    { label: "Name", field: "productname" },
    { label: "Phone", field: "productname" },
    { label: "Amount", field: "productname" },
    { label: "Due Amount", field: "stockcount" },
  ];

  async function getAllStockReport() {
    try {
      const url = "/stock/getallreport";
      const response = await get(url);

      if (response?.data?.success) {
        setAssignstock(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // getAllStockReport();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filtered = assignstock?.filter((item) =>
        item.productname.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setFilteredData(filtered);
    } else {
      setFilteredData(assignstock);
    }
  }, [searchQuery, assignstock]);
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Payment Report</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => {}}
      />
    </Stack>
  );
}

export default PaymentReport;
