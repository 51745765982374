import * as React from "react";
import { Member } from "../../types/member-interface";
import DataTable from "../../components/DataTable";
import { Invoice } from "src/types/invoice-interface";

interface InvoicesTableProps {
  count?: number;
  page?: number;
  rows?: Invoice[];
  rowsPerPage?: number;
  onDeleteCallback?: () => any;
  onViewCallBack?: any;
}

export function InvoicesTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onDeleteCallback,
  onViewCallBack,
}: InvoicesTableProps): React.JSX.Element {
  const rowIds = React.useMemo(() => {
    return rows.map((invoices) => invoices.id);
  }, [rows]);

  const columns = [
    { id: "id", label: "#" },
    { id: "created_at", label: "Date" },
    { id: "billnumber", label: "Bill Number" },
    { id: "netprice", label: "Total Amount" },
  ];
  return (
    <div>
      <DataTable
        rows={rows}
        columns={columns}
        onEdit={() => {}}
        onDelete={() => {}}
        onView={(id) => onViewCallBack(id)}
        showActionView={true}
        showActionEdit={false}
        showActionDelete={false}
        expandButtonIcon="LocalOffer"
      />
    </div>
  );
}
