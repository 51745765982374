import { MagnifyingGlass as MagnifyingGlassIcon } from "@phosphor-icons/react/dist/ssr/MagnifyingGlass";
import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";

import { CONSTANTS } from "../../constants";
import { InvoicesTable } from "./invoice-table";
import { useNavigate } from "react-router-dom";
import axiosService from "../../services/axios";
import { Member } from "../../types/member-interface";
import { Box, Card, OutlinedInput, InputAdornment } from "@mui/material";
import { useAlert } from "../../components/CommonAlert";
import { useAuth } from "../../context/AuthContext";
import { Roles } from "src/types/enum";
import moment from "moment";
import { get } from "src/services/api.service";
import { Invoice } from "src/types/invoice-interface";
import ShowInvoiceModal from "./showInvoiceModal";

const data = {
  products: [
    {
      id: "2wf70pyO",
      desc: "High-quality shampoo for all hair types.",
      productname: "Product 2",
      servicename: "",
      price: "1000.00",
      totalprice: 0,
      productid: "3fwqkcwj",
      type: "Product",
      discount: 0,
      remarks: "",
      units: 3,
      salestaffid: [],
      mainstaffid: [],
      service: [],
      ispercentage: false,
      partial_payment: 3000,
      isKIV: true,
      isCourse: false,
      addedAt: "2024-09-27T11:39:48.000Z",
    },
    {
      id: "H7ZAdxu7",
      desc: "High-quality shampoo for all hair types.",
      productname: "",
      servicename: "Service D",
      price: "10000.00",
      totalprice: 0,
      productid: "1KLKt8by",
      type: "Service",
      discount: 0,
      remarks: "",
      units: 5,
      salestaffid: [],
      mainstaffid: [],
      service: [],
      ispercentage: false,
      partial_payment: 50000,
      isKIV: false,
      isCourse: false,
      addedAt: "2024-09-27T11:39:49.000Z",
    },
    {
      id: "MISyw6OA",
      desc: "High-quality shampoo for all hair types.",
      productname: "",
      servicename: "service C",
      price: "344.00",
      totalprice: 0,
      productid: "7apz9j4i",
      type: "Service",
      discount: 0,
      remarks: "",
      units: 3,
      salestaffid: [],
      mainstaffid: [],
      service: [],
      ispercentage: false,
      partial_payment: 1032,
      isKIV: false,
      isCourse: false,
      addedAt: "2024-09-27T11:39:49.000Z",
    },
    {
      id: "PaunZsKN",
      desc: "High-quality shampoo for all hair types.",
      productname: "test",
      servicename: "",
      price: "99999999.00",
      totalprice: 0,
      productid: "4GjlPR3S",
      type: "Product",
      discount: 0,
      remarks: "",
      units: 3,
      salestaffid: [],
      mainstaffid: [],
      service: [],
      ispercentage: false,
      partial_payment: 300000000,
      isKIV: false,
      isCourse: false,
      addedAt: "2024-09-27T11:39:49.000Z",
    },
  ],
  isKIV: [
    {
      id: 1,
      productName: "Prod 1",
      qty: "10",
    },
    {
      id: 2,
      productName: "Prod 2",
      qty: "11",
    },
  ],
  course: [
    {
      id: 1,
      serviceName: "Service 1",
      qty: "10",
    },
    {
      id: 2,
      serviceName: "Service 2",
      qty: "11",
    },
  ],

  subtotal: 300054000,
  finaldiscount: 0,
  cashpaid: 8390630,
  paytopup: 10000000,
  netprice: 300054000,
  isreceipt: false,
  remarks: "",
  memberid: 3,
  outlet_id: "",
};

export default function InvoicesPage(): React.JSX.Element {
  const page = 0;
  const rowsPerPage = 25;
  const [members, setMembers] = React.useState<Invoice[]>([]);
  const [filteredData, setFilteredData] = React.useState<Invoice[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [inviteLInk, setInviteLink] = React.useState("");
  const [showInvoice, setShowInvoice] = React.useState(false);
  const [invoiceDetails, setInvoiceDetails] = React.useState<any[]>([]);
  const { showAlert } = useAlert();
  const getMembers = async () => {
    try {
      let url = `/cartmain/list`;

      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setMembers(response.data.data);
          setFilteredData(response.data.data);
        } else {
          setMembers([]);
          setFilteredData([]);
          showAlert(
            response?.data?.message ?? "Failed to get invoices",
            "error"
          );
        }
      } else {
        showAlert(response?.data?.message ?? "Failed to get invoices", "error");
      }
    } catch (ex: any) {
      setMembers([]);
      setFilteredData([]);
      showAlert(
        ex?.response?.data?.message ?? "Failed to get invoices",
        "error"
      );
      console.error("Exception Caught", ex);
    }
  };

  async function getInvoicedetailsById(billnumber: any) {
    try {
      let url = `/cartmain/billnumber/${billnumber}`;
      const response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setInvoiceDetails(response.data.data);
        } else {
          console.log("Error in API call ", response.data.data);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getMembers();
  }, []);

  React.useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    setFilteredData(
      members.filter((item: Invoice) => {
        const createdAtStr = item?.created_at
          ? moment(item.created_at, "DD-MM-YYYY").format("DD-MM-YYYY") // Format to DD/MM/YYYY
          : "";

        // Check if the searchQuery is a valid date in DD/MM/YYYY format
        const isDateSearch = moment(
          lowercasedQuery,
          "DD-MM-YYYY",
          true
        ).isValid();

        return (
          (isDateSearch &&
            createdAtStr ===
              moment(lowercasedQuery, "MM/DD/YYYY").format("MM/DD/YYYY")) || // Match by date
          createdAtStr.includes(lowercasedQuery) || // Includes date in the string
          (item?.billnumber &&
            item?.billnumber.toLowerCase().includes(lowercasedQuery))
        );
      })
    );
  }, [searchQuery, members]);

  function onViewCallback(id: any) {
    setShowInvoice(true);
    getInvoicedetailsById(id);
    console.log("hellooo", id);
  }

  const onDeleteCallback = () => getMembers();
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Invoices</Typography>
        </Stack>
        <div>
          <Button
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />}
            variant="text"
            onClick={() => navigate("/cart")}
          >
            Add
          </Button>
        </div>
      </Stack>
      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Invoice"
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <InvoicesTable
        count={filteredData?.length ?? 0}
        page={page}
        rows={filteredData}
        rowsPerPage={rowsPerPage}
        onDeleteCallback={onDeleteCallback}
        onViewCallBack={(id: any) => onViewCallback(id)}
      />

      <ShowInvoiceModal
        visibleCheckout={showInvoice}
        data={invoiceDetails}
        onCancle={() => setShowInvoice(false)}
      />
    </Stack>
  );
}
