import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { CssBaseline, Box } from "@mui/material";

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const shouldHideDrawer =
    location.pathname === "/signup" || location.pathname === "/login";

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={
        location.pathname === "/cart"
          ? { display: "flex" }
          : { display: "flex", height: "100vh" }
      }
    >
      <CssBaseline />
      {!shouldHideDrawer && (
        <ResponsiveDrawer open={drawerOpen} onClose={handleDrawerToggle} />
      )}
      <Box
        component="main"
        sx={
          location.pathname === "/cart"
            ? { flexGrow: 1, p: 2, pt: 10 }
            : {
                flexGrow: 1,
                p: 2,
                pt: 10,
                overflowY: "auto", // Enable vertical scrolling
                maxHeight: "100vh", // Ensure it does not exceed the viewport height
              }
        }
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
