import React, { useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import TimeSlotRow from "./TimeSlotRow"; // Importing the TimeSlotRow component
import TimeSlotCard from "./TimeSlotCard";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const CustomCalendar = ({
  staffMembers,
  timeSlots,
  appointments,
  type,
  appointmentsDataForList,
  eventsForMonths,
  handleDateClickInMonths,
  offDayStaffList,
  viewAppointment,
  onAppointmentClick,
}: any) => {
  const calendarRef = useRef<any>(null);

  const getHeaderToolbar = () => {
    switch (type) {
      case "dayGridMonth":
        return {
          left: "today,prev,next title",
          center: "",
          right: "",
        };
      case "timeGridWeek":
        return {
          left: "",
          center: "prev,next",
          right: "",
        };
      case "timeGridDay":
        return {
          left: "",
          center: "prev,next",
          right: "",
        };
    }
  };

  const isStaffDisabled = (staff: any) => {
    //  if the staff is disabled on the specific date

    return offDayStaffList?.some(
      (offDayStaff: any) => offDayStaff.staffid === staff
    );
  };

  return (
    <Box>
      {/* {(type === "timeGridWeek" || type === "timeGridDay") && (
        <div style={{ marginBottom: "10px" }}>
          <Button variant="contained" style={{ marginRight: "10px" }}>
            Previous Day
          </Button>
          <Button variant="contained">Next Day</Button>
        </div>
      )} */}

      {type === "dayGridMonth" && (
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={type}
          headerToolbar={getHeaderToolbar()}
          events={eventsForMonths}
          dateClick={handleDateClickInMonths}
        />
      )}

      {/* Scrollable container for the table */}
      {type === "timeGridWeek" && (
        <TableContainer
          component={Paper}
          style={{ maxWidth: "100%", overflowX: "auto" }}
        >
          <Table stickyHeader style={{ minWidth: "1200px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "100px",
                    backgroundColor: "#f1f1f1",
                    fontWeight: "bold",
                  }}
                >
                  Time
                </TableCell>
                {staffMembers.map((staff: any) => (
                  <TableCell
                    key={staff.firstName}
                    align="center"
                    style={{
                      backgroundColor: isStaffDisabled(staff.id)
                        ? "#e0e0e0"
                        : "#f1f1f1",
                      fontWeight: "bold",
                      color: isStaffDisabled(staff.id) ? "gray" : "inherit",
                    }}
                  >
                    {staff.firstName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* Table Body for Time Slots */}
            <TableBody>
              {timeSlots?.map((time: any) => (
                <TimeSlotRow
                  key={time}
                  time={time}
                  staffMembers={staffMembers}
                  appointments={appointments}
                  isStaffDisabled={isStaffDisabled}
                  onAppointmentClick={onAppointmentClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {type === "timeGridDay" &&
        appointmentsDataForList.map((slot: any, index: any) => (
          <TimeSlotCard
            key={index}
            timeRange={slot.timeRange}
            appointments={slot.appointments}
            viewAppointment={viewAppointment}
          />
        ))}
    </Box>
  );
};

export default CustomCalendar;
