import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Stack,
  Typography,
  Box,
  Card,
  InputAdornment,
  Paper,
  FormHelperText,
} from "@mui/material";
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import { useAuth } from "src/context/AuthContext";
import axiosService from "src/services/axios";
import { Merchant } from "src/types/merchant-interface";
import { RecordTypes } from "src/types/enum";
import { CONSTANTS } from "src/constants";
import CustomDataTable from "src/components/CustomDataTable";

const PlanManagementPage: React.FC = () => {
  const { user } = useAuth();
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [planData, setPlanData] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState<any>([]);
  const [selectedId, setSelectedId] = useState("");
  const [planName, setPlanName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [serviceData, setServiceData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [planNameError, setPlanNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [serviceError, setServiceError] = useState("");

  const columns = [
    { label: "#", field: "index" },
    { label: "Plan Name", field: "name" },
    { label: "Price", field: "price" },
    { label: "Duration", field: "duration" },
    {
      label: "Services",
      field: "services",
      // render: (rowData: any) => serviceBodyTemplate(rowData),
    },
    { label: "action", field: "" },
  ];

  function resetFields() {
    setPlanName("");
    setPrice("");
    setDuration("");
    setSelectedService([]);
    setSelectedServiceId([]);
    setSelectedId("");
    setSelectedCategory("");
    setPlanNameError("");
    setCategoryError("");
    setPriceError("");
    setDurationError("");
    setServiceError("");
  }

  const onDeleteCancel = () => {
    setDeleteDialogVisible(false);
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setIsEdit(false);
  };

  const confirmEdit = (rowData: any) => {
    setEditDialogVisible(true);
    getDataByPlanID(rowData.id);
    setSelectedId(rowData.id);
    setIsEdit(true);
  };

  const openAddNewPlan = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmDelete = (rowData: any) => {
    setDeleteDialogVisible(true);
    setSelectedId(rowData.id);
  };

  const handleSelectAll = () => {
    setSelectedService(serviceData);
  };

  const handleDeselectAll = () => {
    setSelectedService([]);
  };

  const handleChange = (event: any) => {
    const selected = event.target.value;
    setSelectedService(selected);
    if (selected.length > 0) {
      setServiceError("");
    }
  };

  const serviceBodyTemplate = (rowData: any) => {
    return (
      <div className="action-icons flex flex-column justify-content-start align-items-start">
        {rowData &&
          rowData.services.map((service: any) => {
            return <div key={service.id}>{service.name}</div>;
          })}
      </div>
    );
  };

  async function getAllPlan() {
    try {
      const url = "/plan/list";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setPlanData(response.data.data);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getServiceList() {
    try {
      const url = "/service/dropdown";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setServiceData(response.data.data);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllCategory() {
    try {
      let url = "/category/type/Plan";

      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setCategoryData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deletePlan() {
    try {
      const url = `/plan/delete/${selectedId}`;
      const response = await deleteData(url);
      if (response && response.data) {
        if (response.data.success) {
          setDeleteDialogVisible(false);
          getAllPlan();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error deleting plan: ", error);
    }
  }

  async function addPlan() {
    try {
      const url = "/plan/add";

      const body = {
        name: planName,
        price: parseFloat(price),
        duration: duration.toString(),
        category_id: selectedCategory,
        // outlet_id: selectedBranch,
        outlet_id: "",
        services: selectedService.map((service) => ({
          serviceid: service.id,
          name: service.name,
        })),
      };

      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllPlan();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getDataByPlanID(id: any) {
    try {
      const url = `/plan/${id}`;
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          const plan = response.data.data;
          setPlanName(plan.name || "");
          setPrice(plan.price.toString() || "");
          setDuration(plan.duration.toString() || "");
          setSelectedCategory(plan.category_id);
          setSelectedBranch(plan.outlet_id);
          if (Array.isArray(plan.services)) {
            setSelectedService(plan.services);
          } else {
            console.error("No services found in the plan.");
            setSelectedService([]);
          }
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching plan data: ", error);
    }
  }

  async function updatePlan() {
    try {
      const url = `/plan/${selectedId}`;
      const body = {
        name: planName,
        price: parseFloat(price),
        duration: duration.toString(),

        category_id: selectedCategory,
        // outlet_id: selectedBranch,
        outlet_id: "",
        services: selectedService.map((service) => ({
          serviceid: service.id,
          name: service.name,
        })),
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllPlan();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  useEffect(() => {
    getAllPlan();
    getServiceList();
    getAllCategory();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        planData.filter((item: any) => {
          return (
            item?.name &&
            item?.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      );
    } else {
      setFilteredData(planData);
    }
  }, [searchQuery, planData]);

  // Validation function
  const validateFields = () => {
    let valid = true;

    // Plan Name validation
    if (!planName.trim()) {
      setPlanNameError("Plan Name is required");
      valid = false;
    } else {
      setPlanNameError("");
    }

    // Category validation
    if (!selectedCategory) {
      setCategoryError("Category is required");
      valid = false;
    } else {
      setCategoryError("");
    }

    // Price validation
    // if (!price || price === "0") {
    //   setPriceError("Price must be a positive number");
    //   valid = false;
    // } else {
    //   setPriceError("");
    // }

    if (!price || price === "0") {
      setPriceError("Price must be a positive number");
      valid = false;
    } else if (!price || Number(price) <= 0) {
      setPriceError("Price must be greater than 0");
      valid = false;
    } else {
      setPriceError("");
    }

    // Duration validation
    if (!duration || duration === "0") {
      setDurationError("Duration must be a positive number");
      valid = false;
    } else if (!duration || Number(duration) <= 0) {
      setDurationError("Duration must be greater than 0");
      valid = false;
    } else {
      setDurationError("");
    }

    // Services validation
    if (selectedService.length === 0) {
      setServiceError("At least one service must be selected");
      valid = false;
    } else {
      setServiceError("");
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      // Proceed with form submission
      if (!isEdit) {
        addPlan();
      } else {
        updatePlan();
      }
    }
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Plan Management</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Plan"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => openAddNewPlan()}
        isEditIcon={true}
        isDeleteIcon={true}
        onPressEdit={(rowData: any) => {
          confirmEdit(rowData);
        }}
        onPressDelete={(rowData: any) => {
          confirmDelete(rowData);
        }}
      />

      {/* <Paper>
        <Box sx={{ p: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Plan Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "medium",
                      fontWeight: "bold",
                      backgroundColor: "#E9E9E9",
                    }}
                  >
                    Services
                  </TableCell>
                  {user?.role === "merchant" && (
                    <TableCell
                      sx={{
                        fontSize: "medium",
                        fontWeight: "bold",
                        backgroundColor: "#E9E9E9",
                      }}
                      align="right"
                    >
                      {actionHeaderTemplate()}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((rowData: any, index: number) => (
                  <TableRow key={rowData.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{rowData.name}</TableCell>
                    <TableCell>{rowData.price}</TableCell>
                    <TableCell>{rowData.duration}</TableCell>
                    <TableCell>{serviceBodyTemplate(rowData)}</TableCell>
                    {user?.role === "merchant" && (
                      <TableCell align="right">
                        {actionBodyTemplate(rowData)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper> */}

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deletePlan}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete Plan"}
        title={"Confirm Delete"}
      />

      {/* Add and Edit Dialog */}
      <Dialog
        open={!isEdit ? addDialogVisible : editDialogVisible}
        onClose={onDeleteCancel}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {!isEdit ? "Add New Plan" : "Update Existing Plan"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Plan Name"
            value={planName}
            onChange={(e) => {
              setPlanName(e.target.value);
              if (e.target.value.trim()) {
                setPlanNameError("");
              }
            }}
            fullWidth
            margin="normal"
            error={!!planNameError}
            helperText={planNameError}
          />
          <TextField
            select
            label="Category"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value as string);
              if (e.target.value) {
                setCategoryError("");
              }
            }}
            fullWidth
            margin="normal"
            error={!!categoryError}
            helperText={categoryError}
          >
            {categoryData.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              if (e.target.value !== "0") {
                setPriceError("");
              }
            }}
            fullWidth
            margin="normal"
            type="number"
            error={!!priceError}
            helperText={priceError}
          />
          <TextField
            label="Duration"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
              if (e.target.value !== "0") {
                setDurationError("");
              }
            }}
            fullWidth
            margin="normal"
            type="number"
            error={!!durationError}
            helperText={durationError}
          />
          <FormControl fullWidth margin="normal" error={!!serviceError}>
            <InputLabel>Services</InputLabel>
            <Select
              multiple
              value={selectedService}
              onChange={handleChange}
              input={<OutlinedInput label="Services" />}
              renderValue={(selected) =>
                selected
                  .map((service) => service?.name || "Unknown Service")
                  .join(", ")
              }
            >
              <MenuItem>
                <Checkbox
                  indeterminate={
                    selectedService.length > 0 &&
                    selectedService.length < serviceData.length
                  }
                  checked={selectedService.length === serviceData.length}
                  onChange={(e) =>
                    e.target.checked ? handleSelectAll() : handleDeselectAll()
                  }
                />
              </MenuItem>
              {serviceData.map((service) => (
                <MenuItem key={service.id} value={service}>
                  <Checkbox checked={selectedService.indexOf(service) > -1} />
                  <ListItemText primary={service.name} />
                </MenuItem>
              ))}
            </Select>
            {!!serviceError && <FormHelperText>{serviceError}</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default PlanManagementPage;
