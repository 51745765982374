import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Stack,
  Typography,
  Box,
  Card,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search,
} from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import axiosService from "src/services/axios";
import { Merchant } from "src/types/merchant-interface";
import { RecordTypes } from "src/types/enum";
import { CONSTANTS } from "src/constants";
import CustomDataTable from "src/components/CustomDataTable";

const MultiServicePage = () => {
  interface ServiceErrors {
    [index: number]: string;
  }

  interface QuantityErrors {
    [index: number]: string;
  }

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  const [multiServiceData, setMultiServiceData] = useState<any[]>([]);

  const [serviceData, setServiceData] = useState<any[]>([]);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedServiceName, setSelectedServiceName] = useState("");

  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);

  const [multiServiceNameError, setMultiServiceNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [serviceErrors, setServiceErrors] = useState<ServiceErrors>({});
  const [quantityErrors, setQuantityErrors] = useState<QuantityErrors>({});

  const [services, setServices] = useState([
    { serviceid: "", name: "", quantity: 1 },
  ]);
  const [duration, setDuration] = useState("");
  const [checked, setChecked] = useState<boolean>(false);

  const handleAddService = () => {
    setServices([...services, { serviceid: "", name: "", quantity: 1 }]);
  };

  const handleServiceChange = (index: any, field: any, value: any) => {
    setServices((prevServices: any) => {
      const updatedServices = [...prevServices];
      updatedServices[index] = {
        ...updatedServices[index],
        [field]: value,
      };
      return updatedServices;
    });
  };

  const getAvailableServices = (selectedServiceId: any) => {
    return serviceData.filter(
      (service) => !selectedServiceId.includes(service.id)
    );
  };

  const handleRemoveService = (index: any) => {
    if (index === 0 && services.length === 1) {
      return;
    }
    setServices((prevGroups: any) =>
      prevGroups.filter((_: any, i: any) => i !== index)
    );
  };

  const onDeleteCancel = () => {
    setDeleteDialogVisible(false);
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setIsEdit(false);
  };

  const confirmDelete = (rowData: any) => {
    setDeleteDialogVisible(true);
    setSelectedId(rowData.id);
  };

  const openAddNewService = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmEdit = (rowData: any) => {
    setEditDialogVisible(true);
    setSelectedId(rowData.id);
    getDataByMultiServiceID(rowData.id);
    setIsEdit(true);
  };

  function resetFields() {
    setSelectedCategory("");
    setSelectedServiceName("");
    setSelectedPrice("");
    setDuration("");
    setChecked(false);
    setServices([{ serviceid: "", name: "", quantity: 1 }]);
    setMultiServiceNameError("");
    setCategoryError("");
    setPriceError("");
    setDurationError("");
    setServiceErrors("");
  }

  async function getAllMultiServices() {
    try {
      const url = "/multiservice/list";
      const response = await get(url);
      if (response?.data?.success) {
        setMultiServiceData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addMultiService() {
    try {
      const url = "/multiservice";
      let expirydate = checked ? true : false;
      const body = {
        name: selectedServiceName,
        price: selectedPrice,
        duration: duration,
        category_id: selectedCategory,
        isexpirydate: expirydate,
        services: services,
        // outlet_id: selectedBranch,
        outlet_id: "",
      };

      const response = await post(url, body);
      if (response?.data?.success) {
        setAddDialogVisible(false);
        resetFields();
        getAllMultiServices();
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteMultiService() {
    try {
      const url = `/multiservice/delete/${selectedId}`;
      const response = await deleteData(url);
      if (response?.data?.success) {
        setDeleteDialogVisible(false);
        getAllMultiServices();
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting multi-service: ", error);
    }
  }

  async function getDataByMultiServiceID(id: any) {
    try {
      const url = `/multiservice/${id}`;
      const response = await get(url);
      if (response?.data?.success) {
        const service = response.data.data;
        setSelectedServiceName(service.name);
        setSelectedPrice(service.price);
        setServices(service.services);
        if (service.isexpirydate === 1) {
          setDuration(service.duration);
        }
        setSelectedCategory(service.category_id);
        setChecked(service.isexpirydate);
        setSelectedBranch(service.outlet_id);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching multi-service data: ", error);
    }
  }

  async function updateMultiService() {
    try {
      const url = `/multiservice/${selectedId}`;
      let expirydate = checked ? true : false;
      const body = {
        name: selectedServiceName,
        price: selectedPrice,
        duration: duration,
        category_id: selectedCategory,
        isexpirydate: expirydate,
        services: services,
        outlet_id: "",
      };
      const response = await put(url, body);
      if (response?.data?.success) {
        setEditDialogVisible(false);
        getAllMultiServices();
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getServiceData() {
    try {
      const url = "/service/dropdown";
      const response = await get(url);
      if (response?.data?.success) {
        setServiceData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllCategory() {
    try {
      let url = "/category/type/Multiservice";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setCategoryData(response.data.data);
        } else {
          console.log("Error in Api call, ", response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validateFields = () => {
    let valid = true;

    setServiceErrors({});
    setQuantityErrors({});

    services.forEach((service, index) => {
      let error = "";

      if (!service.serviceid) {
        error = "Service is required";
        valid = false;
      }

      setServiceErrors((prevErrors) => ({
        ...prevErrors,
        [index]: error,
      }));
    });

    if (!selectedServiceName.trim()) {
      setMultiServiceNameError("Multi Service Name is required");
      valid = false;
    } else {
      setMultiServiceNameError("");
    }

    // Category validation
    if (!selectedCategory) {
      setCategoryError("Category is required");
      valid = false;
    } else {
      setCategoryError("");
    }

    // Price validation
    // if (!selectedPrice || selectedPrice === "0") {
    //   setPriceError("Price must be a positive number");
    //   valid = false;
    // } else {
    //   setPriceError("");
    // }
    if (!selectedPrice || selectedPrice === "0") {
      setPriceError("Price must be a positive number");
      valid = false;
    } else if (!selectedPrice || Number(selectedPrice) <= 0) {
      setPriceError("Price must be greater than 0");
      valid = false;
    } else {
      setPriceError("");
    }

    // Duration validation
    // if (!duration || duration === "0") {
    //   setDurationError("Duration must be a positive number");
    //   valid = false;
    // } else {
    //   setDurationError("");
    // }

    if (checked) {
      if (!duration || duration === "0") {
        setDurationError("Duration must be a positive number");
        valid = false;
      } else if (!duration || Number(duration) <= 0) {
        setDurationError("Duration must be greater than 0");
        valid = false;
      } else {
        setDurationError("");
      }
    }

    // // Redeem Points
    // if (!redeemPoints || redeemPoints === "0") {
    //   setRedeemPointsError("Redeem Points must be a positive number");
    //   valid = false;
    // } else {
    //   setRedeemPointsError("");
    // }

    return valid;
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      try {
        if (!isEdit) {
          await addMultiService();
        } else {
          await updateMultiService();
        }
        window.location.reload();
      } catch (error) {
        console.error("Error while saving product:", error);
      }
    }
  };

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status != 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([
          { MerchantName: "--Select Outlet--" } as Merchant,
          ...json,
        ]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  const columns = [
    { label: "#", field: "index" },
    { label: "Name", field: "name" },
    { label: "Price", field: "price" },
    { label: "Duration", field: "duration" },
    { label: "action", field: "" },
  ];

  useEffect(() => {
    getServiceData();
    getAllMultiServices();
    getAllCategory();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        multiServiceData.filter((item: any) => {
          return (
            (item?.categoryname &&
              item?.categoryname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(multiServiceData);
    }
  }, [searchQuery, multiServiceData]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Multi Service</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search Multi Service"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => openAddNewService()}
        isEditIcon={true}
        isDeleteIcon={true}
        onPressEdit={(rowData: any) => {
          confirmEdit(rowData);
        }}
        onPressDelete={(rowData: any) => {
          confirmDelete(rowData);
        }}
      />

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onDeleteCancel}
        onConfirm={deleteMultiService}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete MultiService"}
        title={"Confirm Delete"}
      />

      {/* Add and Edit Dialog */}
      <Dialog
        open={!isEdit ? addDialogVisible : editDialogVisible}
        onClose={onDeleteCancel}
        PaperProps={{
          sx: {
            // width: "40vw",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle>
          {!isEdit ? "Add Multi Services" : "Update Existing Services"}
        </DialogTitle>
        <DialogContent>
          <div className="col-12">
            <TextField
              label="Multi-Service Name"
              value={selectedServiceName}
              onChange={(e) => {
                setSelectedServiceName(e.target.value as string);
                if (e.target.value) {
                  setMultiServiceNameError("");
                }
              }}
              fullWidth
              margin="normal"
              error={!!multiServiceNameError}
              helperText={multiServiceNameError}
            />
          </div>
          <div className="col-12">
            <TextField
              select
              label="category"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value as string);
                if (e.target.value) {
                  setCategoryError("");
                }
              }}
              fullWidth
              error={!!categoryError}
              helperText={categoryError}
              margin="normal"
            >
              {categoryData.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-12">
            <TextField
              label="Price"
              type="number"
              value={selectedPrice}
              onChange={(e) => {
                setSelectedPrice(e.target.value);
                if (e.target.value !== "0") {
                  setPriceError("");
                }
              }}
              fullWidth
              margin="normal"
              error={!!priceError}
              helperText={priceError}
            />
          </div>
          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setDuration("");
                    setDurationError("");
                  }}
                />
              }
              label="Set Expiry date for this package."
            />
          </div>

          {checked && (
            <div className="col-12">
              <TextField
                label="Duration"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                  if (e.target.value !== "0") {
                    setDurationError("");
                  }
                }}
                fullWidth
                margin="normal"
                type="number"
                error={!!durationError}
                helperText={durationError}
              />
            </div>
          )}
          <Grid item xs={12}>
            <label className="font-bold ml-2">Service in the package</label>
          </Grid>
          <div>
            {services.map((service, index) => {
              const selectedServiceIds = services
                .filter((_, idx) => idx !== index)
                .map((service) => service.serviceid);

              return (
                <div className="grid col-12" key={index}>
                  <div className="col-8">
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={!!serviceErrors}
                    >
                      <InputLabel>Service</InputLabel>
                      <Select
                        value={service.serviceid}
                        onChange={(e) => {
                          handleServiceChange(
                            index,
                            "serviceid",
                            e.target.value
                          );
                          const selectedService = serviceData.find(
                            (service) => service.id === e.target.value
                          );
                          handleServiceChange(
                            index,
                            "name",
                            selectedService?.name || ""
                          );
                          if (e.target.value !== "0") {
                            setServiceErrors("");
                          }
                        }}
                        input={<OutlinedInput label="Service" />}
                      >
                        {getAvailableServices(selectedServiceIds).map(
                          (service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {serviceErrors[index] && (
                        <FormHelperText error>
                          {serviceErrors[index]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-3">
                    <TextField
                      label="Qty"
                      value={service.quantity}
                      onChange={(e) =>
                        handleServiceChange(
                          index,
                          "quantity",
                          parseInt(e.target.value)
                        )
                      }
                      fullWidth
                      margin="normal"
                      type="number"
                    />
                  </div>
                  <div className="col-1 mt-1">
                    {services && services.length > 1 && (
                      <span
                        style={{
                          cursor: "pointer",
                          color: "red",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        onClick={() => handleRemoveService(index)}
                      >
                        X
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddService}
            >
              Add Service
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onDeleteCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default MultiServicePage;
