import React from "react";
import {
  AppBar,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  CardMedia,
  Typography,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ForumIcon from "@mui/icons-material/Forum";
import { useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Dashboard: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  };

  const isSmallScreen = useMediaQuery("(max-width:530px)");

  const bookAppointment = [
    {
      discount: "30",
      offertitle: "Today's Special",
      showButton: true,
      buttonLink: "http://localhost:3000/notice/layout2",
      image:
        "https://images.freeimages.com/images/large-previews/8de/missing-link-1519399.jpg?fmt=webp&h=350",
    },
    {
      discount: "20",
      offertitle: "Special offer",
      showButton: true,
      buttonLink: "http://localhost:3000/notice/layout2",
      image:
        "https://images.freeimages.com/images/large-previews/866/what-lies-ahead-1521969.jpg?fmt=webp&h=350",
    },
    {
      discount: "10",
      offertitle: "Offer of the week",
      showButton: false,
      buttonLink: "http://localhost:3000/notice/layout2",
      image:
        "https://images.freeimages.com/images/large-previews/ff5/sydney-harbour-bridge-1558191.jpg?fmt=webp&h=350",
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: "sm",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: "#e0e0df",
      }}
    >
      <AppBar position="sticky" color="transparent" sx={{ boxShadow: "none" }}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            padding: "10px 0px",
          }}
        >
          <Box>
            <Typography variant="h6" component="div" color="primary">
              Hello, ABC
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              sx={{
                bgcolor: "white",
                flexGrow: 1,
                borderRadius: "50px",
                width: isSmallScreen ? "70%" : "82%",
                mr: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton color="inherit">
              <NotificationsIcon />
            </IconButton>
            <IconButton color="inherit">
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, flexGrow: 1 }}>
        <Slider {...settings}>
          {bookAppointment &&
            bookAppointment.length > 0 &&
            bookAppointment.map((booking: any, index: number) => (
              <Card
                key={index}
                sx={{
                  width: "100%",
                  mb: 2,
                  height: "200px",
                  background: "transparent",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={booking.image}
                  alt="Today's Special Background"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    objectFit: "cover",
                    zIndex: -1,
                  }}
                />
                <Typography
                  variant="h6"
                  component="div"
                  p={2}
                  sx={{
                    color: "white",
                    position: "absolute",
                    bottom: 0,
                    left: 4,
                  }}
                >
                  {booking.offertitle}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  p={2}
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: 10,
                    left: 4,
                  }}
                >
                  30% OFF
                </Typography>
              </Card>
            ))}
        </Slider>
      </Box>
      <BottomNavigation
        showLabels
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        value={1}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Promos" icon={<StorefrontIcon />} />
        <BottomNavigationAction label="Reward" icon={<LocalActivityIcon />} />
        <BottomNavigationAction label="Messages" icon={<ForumIcon />} />
      </BottomNavigation>
    </Box>
  );
};

export default Dashboard;
