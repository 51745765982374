import { MenuViewPanel, Roles } from "./types/enum";

export const paths = [
  {
    title: "Home",
    path: "/home",
    icon: "home",
    roles: [
      Roles.superadmin,
      Roles.merchant,
      Roles.outlet,
      Roles.partner,
      Roles.staff,
    ] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Appointment",
    path: "/appointment",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Merchants",
    path: "/merchants",
    icon: "home",
    roles: [Roles.superadmin] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Members",
    path: "/members",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet, Roles.staff] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Staff",
    path: "/staff",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "NoticeBoard",
    path: "/noticeboards",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Outlets",
    path: "/outlet",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.PROFILE,
  },
  {
    title: "Partners",
    path: "/partner",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.PROFILE,
  },
  {
    title: "Bottom Buttons",
    path: "/bottomButtons",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.PROFILE,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.PROFILE,
  },
  {
    title: "Messages",
    path: "/messages",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Coupons",
    path: "/coupons",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "ActivityLogs",
    path: "/activitylogs",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  // {
  //   title: "Invoice",
  //   path: "/invoices",
  //   icon: "home",
  //   roles: [Roles.merchant] as String[],
  //   panel: MenuViewPanel.LEFTNAV,
  // },
  {
    title: "Booking",
    path: "/bookings",
    icon: "home",
    roles: [Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Inventory",
    path: "/inventories",
    icon: "home",
    roles: [Roles.merchant] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: "home",
    roles: [Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  // {
  //   title: "Inventory Report",
  //   path: "/inventoryReport",
  //   icon: "home",
  //   roles: [Roles.merchant, Roles.outlet] as String[],
  //   panel: MenuViewPanel.LEFTNAV,
  // },
  // {
  //   title: "Inventory Stock Report",
  //   path: "/inventoryInOutReport",
  //   icon: "home",
  //   roles: [Roles.merchant] as String[],
  //   panel: MenuViewPanel.LEFTNAV,
  // },
  {
    title: "POS",
    path: "/pos",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: "home",
    roles: [Roles.merchant, Roles.outlet] as String[],
    panel: MenuViewPanel.LEFTNAV,
  },
  // { title: "Inventory", path: '/inventory', icon: "home", roles: [Roles.outlet] as String[], panel: MenuViewPanel.LEFTNAV }
];
