import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Switch,
  DialogActions,
  Grid,
} from "@mui/material";
import {
  Add,
  Clear,
  Delete,
  Close,
  Remove,
  Delete as DeleteIcon,
} from "@mui/icons-material";

interface modalInterface {
  visibleCheckout: boolean;
  onCancle?: any;
  data?: any;
}

function ShowInvoiceModal({ visibleCheckout, onCancle, data }: modalInterface) {
  const isAnySalesStaffPresent =
    data.products &&
    data.products?.some(
      (item: any) => item.salestaffid && item.salestaffid?.length > 0
    );

  const isAnyServiceStaffPresent =
    data.products &&
    data.products?.some(
      (item: any) => item.service && item.service?.length > 0
    );

  const cartDataBodyTemplate = () => {
    return (
      <tbody>
        {data.products &&
          data.products?.map((data: any, index: any) => {
            return (
              <tr key={data.id}>
                <td>{index + 1}. </td>
                <td style={{ textAlign: "left" }}>
                  {data.units} X {data.name}
                </td>
                <td>{parseFloat(data.price).toFixed(2)}</td>

                <td>{parseFloat(data.totalprice).toFixed(2)}</td>
                <td>
                  <div className="flex align-items-center justify-content-center gap-2">
                    {data.ispercentage == true && (
                      <div>{parseFloat(data.discount).toFixed(2)}%</div>
                    )}
                    {data.ispercentage == false && (
                      <div>{parseFloat(data.discount).toFixed(2)}</div>
                    )}
                  </div>
                </td>
                <td>
                  <div>{parseFloat(data.partial_payment).toFixed(2)}</div>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data?.salestaffid?.length == 0 && (
                      // <Button
                      //   aria-hidden="true"
                      //   variant="text"
                      //   // onClick={() => {
                      //   //   confirmSalesStaff(data);
                      //   // }}
                      // >
                      //   Staff
                      // </Button>
                      <div>Staff</div>
                    )}
                    {data?.salestaffid &&
                      data?.salestaffid?.length > 0 &&
                      data.salestaffid.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          {/* <div className="col-4">
                                <Clear
                                  className="text-red-900 cursor-pointer"
                                  onClick={() => {
                                    handleRemoveSalesStaffGroupChange(
                                      data,
                                      item.id
                                    );
                                  }}
                                />
                              </div> */}
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="flex flex-column gap-2">
                    {data?.type == "Service" && data?.service?.length == 0 && (
                      <div>Staff</div>
                      // <Button
                      //   aria-hidden="true"
                      //   variant="text"
                      //   // onClick={() => {
                      //   //   confirmServiceStaff(data);
                      //   // }}
                      // >
                      //   Staff
                      // </Button>
                    )}
                    {data?.type == "Service" &&
                      data?.service &&
                      data?.service?.length > 0 &&
                      data?.service?.map((item: any) => (
                        <div key={item.id} className="grid align-items-center">
                          <div className="col-8 flex flex-column gap-2">
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {item.name}
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.discount).toFixed(2)}%
                            </label>
                            <label className="text-overflow-ellipsis w-full overflow-hidden white-space-normal">
                              {parseFloat(item.price).toFixed(2)}
                            </label>
                          </div>
                          {/* <div className="col-4">
                                <Clear
                                  className="text-red-900 cursor-pointer"
                                  onClick={() => {
                                    handleRemoveServiceStaffGroupChange(
                                      data,
                                      item.id
                                    );
                                  }}
                                />
                              </div> */}
                        </div>
                      ))}
                  </div>
                </td>
                {data.type === "Product" ? (
                  <td>
                    <div>{data.isKIV ? "KIV" : "TAKEN"}</div>
                  </td>
                ) : (
                  <td>-</td>
                )}
                <td>
                  <TextField
                    value={data.remarks}
                    disabled={true}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  };

  return (
    <div>
      <Dialog
        open={visibleCheckout}
        PaperProps={{
          sx: {
            width: "80vw",
            maxWidth: "none",
          },
        }}
        onClose={onCancle}
      >
        <DialogTitle>
          Checkout confirmation
          <IconButton
            aria-label="close"
            onClick={onCancle}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="grid">
            <div className="col-12">
              <label className="form-label">Selected items</label>
              <table className="cart-table mt-2">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Unit Price (RM)</th>
                    <th>Total Sales (RM)</th>
                    <th>Discount</th>
                    <th>Total Amt To Pay (RM)</th>
                    <th
                      style={{
                        textAlign: isAnySalesStaffPresent ? "left" : "center",
                      }}
                    >
                      Sales
                    </th>
                    <th
                      style={{
                        textAlign: isAnyServiceStaffPresent ? "left" : "center",
                      }}
                    >
                      Service
                    </th>
                    <th>KIV / Taken</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                {cartDataBodyTemplate()}
              </table>
            </div>
          </div>

          {/* Redeem KIV Products Section */}
          <div className="grid mt-4">
            <div className="col-12">
              <label className="form-label">Redeem KIV Products</label>
              <table className="cart-table mt-2">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {data.isKIV && data.isKIV.length ? (
                    data.isKIV?.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.productName}</td>
                        <td>{item.qty}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        No items redeemed yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Redeem Courses Section */}
          <div className="grid mt-4">
            <div className="col-12">
              <label className="form-label">Redeem Courses</label>
              <table className="cart-table mt-2">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Name</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {data.isCourse && data.isCourse.length > 0 ? (
                    data.isCourse.map((course: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{course.serviceName}</td>
                        <td>{course.qty}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        No courses redeemed yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="grid mt-3">
            <div className="col-6">
              <div className="grid">
                <div className="col-12">
                  <label className="form-label">Remark</label>
                  <TextField
                    value={data.remarks}
                    // onChange={(e) => setRemark(e.target.value)}
                    disabled={true}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="grid mt-3">
                <div className="col-6 flex gap-2 flex-column align-items-end">
                  <label className="font-semibold">Subtotal</label>
                  <label className="font-semibold">Discount</label>
                  {data.payment_method &&
                    data.payment_method.length > 0 &&
                    data.payment_method.map((payment: any, index: number) => (
                      <label className="font-semibold">{payment.name}</label>
                    ))}
                  {/* {data.cashTopup > 0 && (
                    <label className="font-semibold">Pay with cash</label>
                  )}
                  {data.BankTransfer > 0 && (
                    <label className="font-semibold">Bank Transfer</label>
                  )}
                  {data.CreditCard > 0 && (
                    <label className="font-semibold">Credit Card</label>
                  )}
                  {data.TouchGo > 0 && (
                    <label className="font-semibold">Touch & Go</label>
                  )} */}
                  <label className="text-3xl font-bold">Net amount</label>
                </div>

                <div className="col-6 flex gap-2 flex-column align-items-start">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="font-semibold">
                      {data?.subtotal ? data?.subtotal : "0"}
                    </label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="font-semibold">
                      {data?.inaldiscount ? data?.inaldiscount : "0"}
                    </label>
                  </div>

                  {data.payment_method &&
                    data.payment_method.length > 0 &&
                    data.payment_method.map((payment: any, index: number) => (
                      <label className="font-semibold">{payment.amount}</label>
                    ))}

                  {/* {data.cashTopup > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label className="font-semibold">{data.cashTopup}</label>
                    </div>
                  )}
                  {data.BankTransfer > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label className="font-semibold">
                        {data.BankTransfer}
                      </label>
                    </div>
                  )}
                  {data.CreditCard > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label className="font-semibold">{data.CreditCard}</label>
                    </div>
                  )}
                  {data.TouchGo > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label className="font-semibold">{data.TouchGo}</label>
                    </div>
                  )} */}

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="text-3xl font-bold">
                      {data?.netprice ? data.netprice : "0"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShowInvoiceModal;
