// src/components/ResponsiveDrawer.tsx
import React, { Suspense } from "react";
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Collapse,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import DrawerHeader from "./DrawerHeader";
import { Link, useNavigate } from "react-router-dom";
import { paths } from "../paths";
import { MenuViewPanel, Roles } from "../types/enum";
import { posPaths } from "src/posPaths";
import { reportPaths } from "src/reportPaths";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const ProfileMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    minWidth: 250,
  },
}));

interface WrapperProps {
  children: React.ReactNode;
}

const ResponsiveDrawer: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const { isAuthenticated, user, logout } = useAuth();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAccont = Boolean(anchorEl);
  const [posOpen, setPosOpen] = useState(false); // State for POS dropdown
  const [reportOpen, setReportOpen] = useState(false); // State for Reports dropdown

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    switch (user?.role) {
      case Roles.merchant:
        navigate("/merchantaccount");
        break;
      case Roles.outlet:
        navigate("/outletaccount");
        break;
      case Roles.partner:
        navigate("/partneraccount");
        break;
      default:
        break;
    }

    handleMenuClose();
  };
  const handleLogout = () => {
    logout();
    handleMenuClose();
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePosClick = () => {
    setPosOpen(!posOpen);
  };

  const handleReportClick = () => {
    setReportOpen(!reportOpen);
  };

  // const drawer = (
  //     <div >
  //         <Toolbar >
  //             <img src={`${process.env.PUBLIC_URL}/assets/suibox_logo.jpg`} height={50} />
  //         </Toolbar>
  //         <Divider />
  //         <List>
  //             {paths.map((item) => {
  //                 return (user && item.roles.includes(user.role) && item.panel == MenuViewPanel.LEFTNAV && <ListItem button component={Link} to={item.path} onClick={onClose}>
  //                     <ListItemText primary={item.title} />
  //                 </ListItem>)
  //             })}
  //         </List>
  //     </div>
  // );

  const drawer = (
    <div>
      <Toolbar>
        <img
          src={`${process.env.PUBLIC_URL}/assets/suibox_logo.jpg`}
          height={50}
        />
      </Toolbar>
      <Divider />
      <List>
        {paths.map((item) => {
          if (
            user &&
            item.roles.includes(user.role) &&
            item.panel === MenuViewPanel.LEFTNAV
          ) {
            if (item.path === "/pos") {
              return (
                <React.Fragment key={item.path}>
                  <ListItem button onClick={handlePosClick}>
                    <ListItemText primary={item.title} />
                    {posOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={posOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {posPaths.map(
                        (posItem) =>
                          user &&
                          posItem.roles.includes(user.role) && (
                            <ListItem
                              button
                              component={Link}
                              to={posItem.path}
                              onClick={onClose}
                              key={posItem.path}
                            >
                              <ListItemText primary={posItem.title} />
                            </ListItem>
                          )
                      )}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            } else if (item.path === "/reports") {
              return (
                <React.Fragment key={item.path}>
                  <ListItem button onClick={handleReportClick}>
                    <ListItemText primary={item.title} />
                    {reportOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={reportOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {reportPaths.map(
                        (reportItem) =>
                          user &&
                          reportItem.roles.includes(user.role) && (
                            <ListItem
                              button
                              component={Link}
                              to={reportItem.path}
                              onClick={onClose}
                              key={reportItem.path}
                            >
                              <ListItemText primary={reportItem.title} />
                            </ListItem>
                          )
                      )}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            } else {
              return (
                <ListItem
                  button
                  component={Link}
                  to={item.path}
                  onClick={onClose}
                  key={item.path}
                >
                  <ListItemText primary={item.title} />
                </ListItem>
              );
            }
          }
          return null;
        })}
      </List>
    </div>
  );

  return isAuthenticated ? (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Stack
            sx={{
              flex: "1 1 auto",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onClose}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                sx={{ display: { sm: "none" } }}
                variant="h6"
                noWrap
                component="div"
              >
                SUIBOX
              </Typography>
            </Stack>
            <Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <Avatar
                    alt={user?.account?.name}
                    src={user?.account?.logo}
                    sx={{ width: 25, height: 25, marginBottom: 1 }}
                  />
                </IconButton>
              </Stack>
              <ProfileMenu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt={user?.account?.name}
                    src={user?.account?.logo}
                    sx={{ width: 64, height: 64, marginBottom: 1 }}
                  />
                  <Typography variant="h6">{user?.account?.name}</Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "center" }}
                  >
                    {user?.username} <br />{" "}
                    <Typography
                      sx={{ textTransform: "capitalize", fontSize: "small" }}
                    >
                      {" "}
                      {user?.role}
                    </Typography>
                  </Typography>
                </Box>

                <Divider />
                <MenuItem onClick={handleProfile}>My Account</MenuItem>
                <Divider />
                {paths.map((item) => {
                  return (
                    user &&
                    item.roles.includes(user.role) &&
                    item.panel == MenuViewPanel.PROFILE && (
                      <>
                        <MenuItem onClick={() => navigate(item.path)}>
                          {item.title}
                        </MenuItem>
                        <Divider />
                      </>
                    )
                  );
                })}
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
              </ProfileMenu>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={open}
        onClose={onClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {drawer}
      </Drawer>
      {/* <DrawerHeader onClose={onClose} /> */}
    </div>
  ) : (
    <></>
  );
};
export default ResponsiveDrawer;
