import { Card, Typography, ButtonBase } from "@mui/material";

const AppointmentCell = ({
  time,
  staff,
  appointments,
  onAppointmentClick,
}: any) => {
  const appointment = appointments?.find(
    (appt: any) => appt.staff === staff.firstName && appt.time === time
  );

  const handleClick = () => {
    if (appointment && onAppointmentClick) {
      onAppointmentClick(appointment); // Trigger the click callback with appointment details
    }
  };

  return appointment ? (
    <ButtonBase onClick={handleClick} style={{ width: "100%" }}>
      <Card
        variant="outlined"
        style={{
          backgroundColor: "#3f51b5",
          color: "#fff",
          padding: "10px",
          maxWidth: "120px",
          margin: "0 auto",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer", // Indicate it's clickable
        }}
      >
        <Typography variant="body2">{appointment.title}</Typography>
      </Card>
    </ButtonBase>
  ) : null; // Return empty if no appointment found for that time and staff
};

export default AppointmentCell;
