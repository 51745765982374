import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Box,
  InputAdornment,
  Grid,
  Stack,
  Typography,
  Card,
  OutlinedInput,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { deleteData, get, post, put } from "../../services/api.service";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog";
import axiosService from "src/services/axios";
import { Merchant } from "src/types/merchant-interface";
import { CONSTANTS } from "src/constants";
import { RecordTypes } from "src/types/enum";
import CustomDataTable from "src/components/CustomDataTable";

const InventoryPage: React.FC = () => {
  const rowsPerPageOptions = [10, 25, 50, 100];

  const [selectedBranch, setSelectedBranch] = useState<string>("Abc Salon");
  const [deleteDialogVisible, setDeleteDialogVisible] =
    useState<boolean>(false);
  const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [checked, setChecked] = useState<boolean>(false);

  const [selectedProductId, setSelectedProductId] = useState("");
  const [productsData, setProductsData] = useState<any[]>([]);

  const [stockName, setStockName] = useState("");
  const [stockCount, setStockCount] = useState("");
  const [price, setPrice] = useState("");
  const [remarks, setRemarks] = useState("");
  const [stockData, setStockData] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [PriceError, setPriceError] = useState("");
  const [stockpurchaseDialogVisible, setStockpurchaseDialogVisible] =
    useState<boolean>(false);
  const [StockassignDialogVisible, setStockassignDialogVisible] =
    useState<boolean>(false);
  const [stocklossDialogVisible, setStocklossDialogVisible] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [outlets, setOutlets] = React.useState<Merchant[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [SelectedOutletError, setSelectedOutletError] = useState("");
  const [SelectedstockCountError, setstockCountError] = useState("");
  const [productError, setProductError] = useState("");
  const [stockQuantityError, setStockQuantityError] = useState("");

  const columns = [
    { label: "#", field: "index" },
    { label: "Product", field: "productname" },
    { label: "Stock Quantity", field: "stockcount" },
    { label: "KIV Stock", field: "inKIVStock" },
    { label: "Remark", field: "remarks" },
    { label: "action", field: "" },
  ];

  function resetFields() {
    setStockName("");
    setSelectedBranch("");
    setStockCount("");
    setRemarks("");
    setSelectedBranchId("");
    setSelectedProductId("");
    setSelectedId("");
    setSelectedOutlet("");
    setSelectedOutletError("");
    setstockCountError("");
    setPrice("");
    setProductError("");
    setStockQuantityError("");
  }

  const validateFields = () => {
    let valid = true;

    if (!selectedOutlet) {
      setSelectedOutletError("Outlet is required");
      valid = false;
    } else {
      setSelectedOutletError("");
    }

    if (!stockCount || stockCount === "0") {
      setstockCountError("Quantity must be a positive number");
      valid = false;
    } else if (Number(stockCount) <= 0) {
      setstockCountError("Quantity must be a positive number");
      valid = false;
    } else {
      setstockCountError("");
    }

    return valid;
  };

  const purchaseValidations = () => {
    let valid = true;
    if (!stockCount || stockCount === "0") {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else if (Number(stockCount) <= 0) {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else {
      setStockQuantityError("");
    }

    if (!price || price === "0") {
      setPriceError("Price must be a positive number");
      valid = false;
    } else if (Number(price) <= 0) {
      setPriceError("Price must be a positive number");
      valid = false;
    } else {
      setPriceError("");
    }
    return valid;
  };

  const lossValidations = () => {
    let valid = true;
    if (!stockCount || stockCount === "0") {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else if (Number(stockCount) <= 0) {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else {
      setStockQuantityError("");
    }

    return valid;
  };

  const validations = () => {
    let valid = true;

    if (!selectedProductId) {
      setProductError("Prouct is required");
      valid = false;
    } else {
      setProductError("");
    }

    if (!stockCount || stockCount === "0") {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else if (Number(stockCount) <= 0) {
      setStockQuantityError("Quantity must be a positive number");
      valid = false;
    } else {
      setStockQuantityError("");
    }
    return valid;
  };

  const openAddNewStock = () => {
    setAddDialogVisible(true);
    resetFields();
  };

  const confirmDelete = (rowData: any) => {
    setSelectedId(rowData.id);
    setDeleteDialogVisible(true);
  };

  const confirmEdit = (rowData: any) => {
    setSelectedRowData(rowData);
    setEditDialogVisible(true);
    getStockById(rowData.id);
    setSelectedId(rowData.id);
    setIsEdit(true);
  };

  const onCancel = () => {
    setDeleteDialogVisible(false);
    setAddDialogVisible(false);
    setEditDialogVisible(false);
    setStockpurchaseDialogVisible(false);
    setStocklossDialogVisible(false);
    setStockassignDialogVisible(false);
    setIsEdit(false);
    resetFields();
  };

  const confirmStockPurchase = (rowData: any) => {
    resetFields();
    setSelectedId(rowData.id);
    setSelectedRowData(rowData);
    setStockpurchaseDialogVisible(true);
  };

  const confirmStockassign = (rowData: any) => {
    setSelectedId(rowData.id);
    setSelectedRowData(rowData);
    setStockassignDialogVisible(true);
  };

  const confirmStockLoss = (rowData: any) => {
    setSelectedId(rowData.id);
    setSelectedRowData(rowData);
    setStocklossDialogVisible(true);
  };

  async function assignStockToOutlet() {
    if (validateFields()) {
      setDisableBtn(true);
      try {
        let url = "/assignstock";
        let body = {
          stockid: selectedId,
          merchant_id: "",
          outlet_id: selectedOutlet,
          assignstock: stockCount,
          remarks: remarks,
        };
        const response = await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            setStockassignDialogVisible(false);
            setDisableBtn(false);
            getAllStock();
            resetFields();
            //getAllProduct();
          } else {
            console.log("Error in Api call ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getProduct() {
    try {
      const url = "/product/dropdown/stock";
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setProductsData(response.data.data);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function addNewStock() {
    try {
      const url = "/stock";

      const body = {
        name: stockName,
        stockcount: parseInt(stockCount),
        remarks: remarks,
        isproductlinked: checked,
        productid: selectedProductId,
        merchant_id: "",
        outlet_id: "",
      };

      const response = await post(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setAddDialogVisible(false);
          getAllStock();
          resetFields();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllStock() {
    try {
      const url = "/stock/getall";
      const response = await get(url);
      if (response?.data?.success) {
        setStockData(response.data.data);
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteStock() {
    try {
      const url = `/stock/delete/${selectedId}`;
      const response = await deleteData(url);
      if (response?.data?.success) {
        setDeleteDialogVisible(false);
        getAllStock();
      } else {
        console.log("Error in API call: ", response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting multi-service: ", error);
    }
  }

  async function editStock() {
    try {
      const url = `/stock/${selectedId}`;

      const body = {
        name: stockName,
        stockcount: parseInt(stockCount),
        remarks: remarks,
        isproductlinked: checked,
        productid: checked ? selectedProductId : "",
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          setEditDialogVisible(false);
          getAllStock();

          resetFields();
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function stockPurchase() {
    if (purchaseValidations()) {
      try {
        const url = `/stock/stockpurchase/${selectedId}`;

        const body = {
          stockcount: parseInt(stockCount),
          remarks: remarks,
          price: parseInt(price),
          isPurchase: true,
        };

        const response = await put(url, body);
        if (response && response.data) {
          if (response.data.success) {
            setStockpurchaseDialogVisible(false);
            getAllStock();
            resetFields();
          } else {
            console.error("Error in API call: ", response.data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function stockLoss() {
    if (lossValidations()) {
      try {
        const url = `/stock/stockloss/${selectedId}`;

        const body = {
          stockcount: parseInt(stockCount),
          remarks: remarks,
          isPurchase: false,
        };

        const response = await put(url, body);
        if (response && response.data) {
          if (response.data.success) {
            setStocklossDialogVisible(false);
            getAllStock();
            resetFields();
          } else {
            console.error("Error in API call: ", response.data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function getStockById(id: string) {
    try {
      const url = `/stock/${id}`;
      const response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          const stockData = response.data.data;
          setStockName(stockData.name || "");
          setStockCount(
            stockData.stockcount ? stockData.stockcount.toString() : ""
          );
          setRemarks(stockData.remarks || "");
          setChecked(true);
          setSelectedProductId(stockData.productid || "");
          setSelectedRowData(stockData);
        } else {
          console.error("Error in API call: ", response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getOutlets = async () => {
    try {
      const response = await axiosService.get<Merchant[]>(
        `${CONSTANTS.ApiConstants.GENERIC.get[RecordTypes.outlet]}`
      );
      if (response.status !== 200) {
        setOutlets([]);
      } else {
        const json = response.data;
        setOutlets([...json]);
      }
    } catch (ex) {
      setOutlets([]);
      console.error("Exception Caught", ex);
    }
  };

  const handleSubmit = () => {
    if (validations()) {
      if (!isEdit) {
        addNewStock();
      } else {
        editStock();
      }
    }
  };

  useEffect(() => {
    getAllStock();
    // getBranchList();
    getProduct();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchQuery && searchQuery.length > 1) {
      setFilteredData(
        stockData?.filter((item: any) => {
          return (
            (item?.productname &&
              item?.productname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item?.name &&
              item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
      );
    } else {
      setFilteredData(stockData);
    }
  }, [searchQuery, stockData]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: "1 1 auto" }}>
          <Typography variant="h4">Inventory</Typography>
        </Stack>
      </Stack>

      <Box>
        <Card sx={{ p: 2 }}>
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            placeholder="Search stock"
            startAdornment={
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            }
            sx={{ maxWidth: "500px" }}
          />
        </Card>
      </Box>

      <CustomDataTable
        columns={columns}
        data={filteredData.map((row: any, index: any) => ({
          ...row,
          index: index + 1,
        }))}
        openAddNew={() => openAddNewStock()}
        isEditIcon={true}
        isDeleteIcon={true}
        isAssign={true}
        isUpArrow={true}
        isDownArrow={true}
        onPressEdit={(rowData: any) => {
          confirmEdit(rowData);
        }}
        onPressDelete={(rowData: any) => {
          confirmDelete(rowData);
        }}
        onAssignClick={(rowData: any) => {
          confirmStockassign(rowData);
        }}
        onUpArrowClick={(rowData: any) => {
          confirmStockPurchase(rowData);
        }}
        onDownArrowClick={(rowData: any) => {
          console.log("lll");

          confirmStockLoss(rowData);
        }}
      />

      {/* Delete Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogVisible}
        onClose={onCancel}
        onConfirm={deleteStock}
        confirmLabel={"Delete"}
        cancelLabel={"Cancel"}
        message={"Are you sure you want to delete Stock"}
        title={"Confirm Delete"}
      />

      {/* Add && Edit New Stock Dialog */}
      <Dialog
        open={!isEdit ? addDialogVisible : editDialogVisible}
        onClose={onCancel}
      >
        <DialogTitle>
          {!isEdit ? "Add New Stock" : "Update Existing Stock"}
        </DialogTitle>
        <DialogContent>
          {/* <TextField
            label="Stock Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={stockName}
            onChange={(e) => setStockName(e.target.value)}
          /> */}
          <TextField
            select
            style={{ marginTop: "5px" }}
            label="Select Product"
            value={selectedProductId}
            // onChange={(e) => setSelectedProductId(e.target.value as string)}
            onChange={(e) => {
              setSelectedProductId(e.target.value as string);
              if (e.target.value) {
                setProductError("");
              }
            }}
            fullWidth
            error={!!productError}
            helperText={productError}
          >
            {productsData.map((product: any) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Stock Quantity"
            variant="outlined"
            onChange={(e) => {
              setStockCount(e.target.value);
              if (e.target.value !== "0") {
                setStockQuantityError("");
              }
            }}
            fullWidth
            margin="normal"
            value={stockCount}
            type="number"
            error={!!stockQuantityError}
            helperText={stockQuantityError}
          />
          <TextField
            label="Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="Link this stock to product."
          />
          {checked && (
            <TextField
              select
              label="Select Product"
              value={selectedProductId}
              onChange={(e) => setSelectedProductId(e.target.value as string)}
              fullWidth
              margin="normal"
              style={{ width: "547px" }}
              InputProps={{
                style: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            >
              {productsData.map((product: any) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </TextField>
          )} */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock Purchase Dialog */}
      <Dialog open={stockpurchaseDialogVisible} onClose={onCancel}>
        <DialogTitle
          sx={{
            paddingBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <label className="font-bold">Stock Purchase</label>
          </Grid>
          <div className="grid col-12">
            <label className="text-base">
              Product Name: {selectedRowData?.productname} (
              {selectedRowData?.stockcount} qty)
            </label>
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Stock Quantity"
            variant="outlined"
            onChange={(e) => {
              setStockCount(e.target.value);
              if (e.target.value !== "0") {
                setStockQuantityError("");
              }
            }}
            fullWidth
            margin="normal"
            value={stockCount}
            type="number"
            error={!!stockQuantityError}
            helperText={stockQuantityError}
          />
          {/* <TextField
            label="Stock Quantity"
            value={stockCount}
            onChange={(e) => setStockCount(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
          /> */}
          <TextField
            label="Total cost for this batch of stock(Not unit cost)"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
              if (e.target.value !== "0") {
                setPriceError("");
              }
            }}
            id="outlined-start-adornment"
            fullWidth
            margin="normal"
            error={!!PriceError}
            helperText={PriceError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">RM</InputAdornment>
              ),
            }}
          />

          <TextField
            label="Remark"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Update Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={stockPurchase} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock assign  Dialog */}
      <Dialog open={StockassignDialogVisible} onClose={onCancel}>
        <DialogTitle
          sx={{
            paddingBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <label className="font-bold">Stock Assign</label>
          </Grid>
          <div className="grid col-12">
            <label className="text-base">
              Product Name: {selectedRowData?.productname} (
              {selectedRowData?.stockcount} qty)
            </label>
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Select Outlet"
            value={selectedOutlet}
            onChange={(e) => {
              setSelectedOutlet(e.target.value as string);
              if (e.target.value) {
                setSelectedOutletError("");
              }
            }}
            fullWidth
            error={!!SelectedOutletError}
            helperText={SelectedOutletError}
            margin="normal"
          >
            {outlets.map((outlet: any) => (
              <MenuItem key={outlet.id} value={outlet.id}>
                {outlet.MerchantName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Qty to assign"
            value={stockCount}
            onChange={(e) => {
              const value: any = parseInt(e.target.value, 10);

              // Validate if the entered quantity is less than or equal to available stock
              if (value > selectedRowData?.stockcount) {
                setstockCountError(
                  `Cannot assign more than ${selectedRowData?.stockcount} units`
                );
              } else {
                setStockCount(value);
                setstockCountError(""); // Clear error if within valid range
              }
            }}
            fullWidth
            error={!!SelectedstockCountError}
            helperText={SelectedstockCountError}
            margin="normal"
            type="number"
          />

          <TextField
            label="Remark"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Update Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={assignStockToOutlet}
            color="primary"
            variant="contained"
            disabled={disableBtn}
          >
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Stock Loss Dialog */}
      <Dialog open={stocklossDialogVisible} onClose={onCancel}>
        <DialogTitle
          sx={{
            paddingBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <label className="font-bold">Stock Less</label>
          </Grid>
          <div className="grid col-12">
            <label className="text-base">
              Product Name: {selectedRowData?.productname} (
              {selectedRowData?.stockcount} qty)
            </label>
          </div>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Quantity of stock loss"
            value={stockCount}
            onChange={(e) => {
              setStockCount(e.target.value);
              if (e.target.value !== "0") {
                setStockQuantityError("");
              }
            }}
            fullWidth
            margin="normal"
            type="number"
            error={!!stockQuantityError}
            helperText={stockQuantityError}
          />
          <TextField
            label="Remark"
            placeholder="Update Remark"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={stockLoss} color="primary" variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="outlined" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default InventoryPage;
