import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
  TablePagination,
  Typography,
  Chip,
} from "@mui/material";
import {
  Delete,
  Edit,
  Add,
  Visibility,
  Assignment,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import { useAuth } from "src/context/AuthContext";

interface Column {
  label: string;
  field: any;
}

interface DataRow {
  [key: string]: any;
}

interface CustomDataTableProps {
  columns: Column[];
  data: DataRow[];
  openAddNew: any;
  onPressEdit?: any;
  onPressDelete?: any;
  isShowIcon?: boolean;
  isEditIcon?: boolean;
  isDeleteIcon?: boolean;
  isDownArrow?: boolean;
  isUpArrow?: boolean;
  isAssign?: boolean;
  isStockAction?: boolean;
  onAssignClick?: any;
  onUpArrowClick?: any;
  onDownArrowClick?: any;
  handleAcceptClick?: any;
  handleRejectClick?: any;
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({
  columns,
  data,
  openAddNew,
  onPressEdit,
  onPressDelete,
  isShowIcon,
  isEditIcon,
  isDeleteIcon,
  isDownArrow,
  isUpArrow,
  isAssign,
  isStockAction,
  onAssignClick,
  onUpArrowClick,
  onDownArrowClick,
  handleAcceptClick,
  handleRejectClick,
}) => {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) =>
              column.label != "action" ? (
                <TableCell
                  sx={{
                    fontSize: "medium",
                    fontWeight: "bold",
                    backgroundColor: "#e9e9e9",
                  }}
                  key={index}
                >
                  {column.label}
                </TableCell>
              ) : user?.role === "merchant" ? (
                <TableCell
                  sx={{
                    fontSize: "medium",
                    fontWeight: "bold",
                    backgroundColor: "#e9e9e9",
                  }}
                  className="flex justify-content-center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={openAddNew}
                  >
                    Add New
                  </Button>
                </TableCell>
              ) : (
                <TableCell
                  sx={{
                    fontSize: "medium",
                    fontWeight: "bold",
                    backgroundColor: "#e9e9e9",
                    alignSelf: "center",
                  }}
                  key={index}
                >
                  {column.label}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData && paginatedData.length > 0 ? (
            paginatedData?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) =>
                  column.label !== "action" ? (
                    column.label === "Status" ? (
                      <TableCell>
                        <Chip
                          label={row.status}
                          color={
                            row.status === "Not assigned"
                              ? "default"
                              : row.status === "Accepted" || row.status === "IN"
                              ? "success"
                              : row.status === "Rejected" ||
                                row.status === "OUT"
                              ? "error"
                              : "primary"
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={colIndex}>{row[column.field]}</TableCell>
                    )
                  ) : user?.role === "merchant" ? (
                    <TableCell className="action-icons" key={colIndex}>
                      {isAssign && (
                        <IconButton
                          color="primary"
                          onClick={() => onAssignClick(row)}
                        >
                          <Assignment />
                        </IconButton>
                      )}
                      {isUpArrow && (
                        <IconButton
                          color="primary"
                          onClick={() => onUpArrowClick(row)}
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}
                      {isDownArrow && (
                        <IconButton
                          color="default"
                          onClick={() => onDownArrowClick(row)}
                        >
                          <ArrowDownward />
                        </IconButton>
                      )}
                      {isShowIcon && (
                        <IconButton
                          style={{
                            color: "#264164",
                          }}
                        >
                          <Visibility
                            style={{ height: "19px", width: "19px" }}
                          />
                        </IconButton>
                      )}
                      {isEditIcon && (
                        <IconButton
                          color="primary"
                          onClick={() => onPressEdit(row)}
                        >
                          <Edit style={{ height: "19px", width: "19px" }} />
                        </IconButton>
                      )}
                      {isDeleteIcon && (
                        <IconButton
                          color="error"
                          onClick={() => onPressDelete(row)}
                        >
                          <Delete style={{ height: "19px", width: "19px" }} />
                        </IconButton>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell className="action-icons">
                      {isStockAction && (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            disabled={row.status !== "Pending"}
                            variant="contained"
                            color="success"
                            onClick={() => handleAcceptClick(row)}
                          >
                            Accept
                          </Button>
                          <Button
                            disabled={row.status !== "Pending"}
                            variant="contained"
                            color="error"
                            onClick={() => handleRejectClick(row)}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography
                  variant="h6"
                  align="center"
                  color="textSecondary"
                  style={{ padding: "16px" }}
                >
                  No data found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </TableContainer>
  );
};

export default CustomDataTable;
