import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function AppointmentDetailsModal({
  open,
  onClose,
  onEdit,
  onDelete,
  appointment,
}: any) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Appointment Details</Typography>
          <Box>
            <IconButton onClick={onEdit} color="primary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            border: "1px solid #dcd3d3",
            margin: "10px",
            padding: "0px",
            borderRadius: "4px",
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Walk-in name
                </TableCell>
                <TableCell>{appointment.customername}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Phone number
                </TableCell>
                <TableCell>{appointment.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Appointment time
                </TableCell>
                <TableCell>{appointment.time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Staff
                </TableCell>
                <TableCell>{appointment.staff}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Outlet
                </TableCell>
                <TableCell>{appointment.branch}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Service
                </TableCell>
                <TableCell>
                  {appointment.services && appointment.services.length > 0
                    ? appointment.services
                        .map((service: any) => service.name)
                        .join(", ")
                    : "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head" style={{ fontWeight: "bold" }}>
                  Remark
                </TableCell>
                <TableCell>{appointment.remark || "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AppointmentDetailsModal;
